import { Button, cn, Table, Typography } from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import CoursesList from "components/CoursesList"
import TableWrapper from "components/TableWrapper"
import { Link } from "react-router-dom"
import { routeBuilder } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import { routes } from "utils/routes"

import { formatAssignmentTitle } from "../../Assignments/utils"

type Props = {
  search?: string
  courseId?: string
}

const AssignmentsList = ({ search, courseId }: Props) => {
  const { isFetching, hasNextPage, fetchNextPage, data, isLoading } =
    useInfiniteQuery({
      queryKey: ["assignments-list", search, courseId],
      queryFn: x =>
        api.assignments.list({
          params: {
            page: x.pageParam,
            search,
            courses: courseId || null,
          },
        }),
      initialPageParam: 1,
      getNextPageParam: lastPage =>
        lastPage.isSuccessful ? lastPage.data.nextPage : undefined,
    })

  const assignments =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  const totalRecords = data?.pages[0].data.total

  return (
    <TableWrapper
      initialLoading={isLoading && isFetching}
      scrollLoading={!isLoading && isFetching && assignments.length > 0}
      showNoData={!isLoading && assignments.length === 0}
      content={className => (
        <Table
          className={cn(className, "mt-2")}
          data={assignments}
          headers={[
            {
              title: "Assignment",
              id: "assignment",
              columnComponent: ({ row }) => (
                <td>
                  <div ref={ref} className="flex flex-col justify-center">
                    <Typography className="text-onSurface-800">
                      {formatAssignmentTitle(row)}
                    </Typography>
                  </div>
                </td>
              ),
            },
            {
              title: "Courses",
              id: "courses",
              columnComponent: ({ row: { courses } }) => (
                <td>
                  <CoursesList courses={courses.map(x => x.name)} />
                </td>
              ),
            },
            {
              title: "Total Submissions",
              id: "total_submission",
              columnComponent: ({ row }) => (
                <td>
                  <Typography>{row.totalSubmissions || `-`}</Typography>
                </td>
              ),
            },
            {
              title: "Pending Reviews",
              id: "pending_reviews",
              columnComponent: ({ row }) => (
                <td>
                  <Typography>{row.pendingReviews || `-`}</Typography>
                </td>
              ),
            },
            {
              title: totalRecords
                ? `${assignments.length} of ${totalRecords} records`
                : "",
              id: "",
              align: "right",
              columnComponent: ({ row }) => (
                <td align="right">
                  <Link
                    to={routeBuilder(routes.assignment.view, {
                      uuid: row.uuid,
                    })}
                  >
                    <Button variant="text" className="min-w-18">
                      View Assignment
                    </Button>
                  </Link>
                </td>
              ),
            },
          ]}
          multiSort={false}
        />
      )}
    />
  )
}

export default AssignmentsList
