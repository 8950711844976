import { AuthData } from "api/resources/auth/types"
import { transformKeys } from "api/utils"
import camelCase from "lodash/camelCase"
import { BROWSER_STORAGE_KEYS } from "utils/constants"

import { routes } from "./routes"

export function saveAuthInfo(authInfo: AuthData) {
  localStorage.setItem(BROWSER_STORAGE_KEYS.auth, JSON.stringify(authInfo))
}

export function getAuthInfo() {
  const authInfo = localStorage.getItem("auth")

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  return authInfo
}

export function clearAuthInfo() {
  const { auth } = BROWSER_STORAGE_KEYS
  localStorage.removeItem(auth)
}

export const redirectToLogin = () => {
  const url = new URL(routes.root, window.location.origin)
  url.searchParams.set(
    "next",
    window.location.pathname + window.location.search
  )
  window.location.href = url.toString()
}
