import { Avatar, Button, cn, Table, Tag, Typography } from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { AttendanceMark } from "api/resources/lectures/types"
import AsyncBuilder from "components/AsyncBuilder"
import CoursesList from "components/CoursesList"
import Navbar from "components/Navbar"
import PageContainer from "components/PageContainer"
import BackButton from "components/shared/BackButton"
import TableWrapper from "components/TableWrapper"
import { Link, useParams } from "react-router-dom"
import { formatDate, handleErrors } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

const Lecture = () => {
  const params = useParams()
  const { id = "" } = params

  const { isFetching, hasNextPage, fetchNextPage, isLoading, data, refetch } =
    useInfiniteQuery({
      queryKey: ["classes", id],
      queryFn: x =>
        api.lectures.getClassOverview({
          urlParams: {
            classId: id,
          },
          params: {
            page: x.pageParam,
          },
        }),
      initialPageParam: 1,
      getNextPageParam: lastPage => {
        return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
      },
    })

  const allData =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []
  const attendanceRecords = allData.map(d => d.attendanceStatus).flat()

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })
  const records = attendanceRecords
  const totalRecords = data?.pages[0].data.total

  const classSchedule = allData[0]?.classSchedule || null

  const onMarkAttendance = async (
    id: string,
    mark: AttendanceMark.ABSENT | AttendanceMark.PRESENT
  ) => {
    try {
      await api.lectures.markAttendance({
        urlParams: { id },
        data: {
          status: mark,
        },
      })
      await refetch()
    } catch (error) {
      handleErrors(error)
    }
  }

  return (
    <>
      <Navbar gutterBottom={false} hideBackButton />
      {classSchedule && (
        <PageContainer className="border-b">
          <div className="flex flex-col justify-between gap-1 py-2 md:flex-row">
            <div>
              <Typography variant="title2">
                {classSchedule.classId.name}
              </Typography>
              <Typography
                variant="smallBody"
                className="mt-1 text-onSurface-700"
              >
                <CoursesList courses={classSchedule.courses} />
              </Typography>
              <Typography variant="smallBody" className="text-onSurface-700">
                {`${formatDate(
                  classSchedule.startTime,
                  "MMMM dd, yyyy h:mm a"
                )} - ${formatDate(
                  classSchedule.endTime,
                  "MMMM dd, yyyy h:mm a"
                )}`}
              </Typography>
            </div>
            <div className="self-end justify-self-end md:self-center">
              <Button
                size="lg"
                disabled={!classSchedule?.videoMeeting?.meetingUrl}
              >
                <Link
                  to={classSchedule?.videoMeeting?.meetingUrl || ""}
                  target="_blank"
                >
                  Join Class
                </Link>
              </Button>
            </div>
          </div>
        </PageContainer>
      )}

      <PageContainer>
        <div className="mt-2 flex flex-col gap-2">
          <BackButton />
        </div>

        <TableWrapper
          initialLoading={isLoading && isFetching}
          addBottomPadding
          scrollLoading={!isLoading && isFetching && records.length > 0}
          showNoData={!isLoading && records.length === 0}
          content={className => (
            <Table
              className={cn(className, "mt-2")}
              headers={[
                {
                  title: "Name",
                  id: "name",
                  columnComponent: ({ row }) => {
                    const name = [row.user.firstName, row.user.lastName]
                      .filter(Boolean)
                      .join(" ")
                    return (
                      <td>
                        <div ref={ref} className="flex items-center gap-0.5">
                          <Avatar
                            name={name}
                            src={row.user.photo}
                            className="size-6"
                          />
                          <Typography variant="body">{name}</Typography>
                        </div>
                      </td>
                    )
                  },
                },
                {
                  title: "Email",
                  id: "email",
                  columnComponent: ({ row }) => (
                    <td>
                      <Typography>{row.user.email}</Typography>
                    </td>
                  ),
                },
                {
                  title: totalRecords
                    ? `${records.length} of ${totalRecords} records`
                    : "",
                  id: "",
                  align: "right",
                  columnComponent: ({ row }) => (
                    <td align="right">
                      {row.status === AttendanceMark.PRESENT && (
                        <Tag color="success">Present</Tag>
                      )}
                      {row.status === AttendanceMark.ABSENT && (
                        <Tag color="critical">Absent</Tag>
                      )}
                      {row.status === AttendanceMark.YET_TO_START && (
                        <div className="flex items-center justify-end gap-1">
                          <AsyncBuilder
                            handler={() =>
                              onMarkAttendance(row.uuid, AttendanceMark.PRESENT)
                            }
                            render={({ loading, onClick }) => (
                              <Button
                                size="xs"
                                variant="text"
                                className="text-success-400"
                                loading={loading}
                                onClick={onClick}
                              >
                                Mark Present
                              </Button>
                            )}
                          />
                          <AsyncBuilder
                            handler={() =>
                              onMarkAttendance(row.uuid, AttendanceMark.ABSENT)
                            }
                            render={({ loading, onClick }) => (
                              <Button
                                size="xs"
                                variant="text"
                                className="text-critical-400"
                                loading={loading}
                                onClick={onClick}
                              >
                                Mark Absent
                              </Button>
                            )}
                          />
                        </div>
                      )}
                    </td>
                  ),
                },
              ]}
              data={records}
              multiSort={false}
            />
          )}
        />
      </PageContainer>
    </>
  )
}

export default Lecture
