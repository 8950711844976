import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  PaginatedResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import {
  CalendarSchedule,
  ClassOverview,
  MarkAttendanceResponse,
} from "./types"
import { urls } from "./urls"

export default {
  getCalendarSchedule: async ({
    params,
  }: Config): Promise<PaginatedResponse<CalendarSchedule[]>> => {
    try {
      const res = await axios.get(urls.getCalendarSchedule(), {
        params,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getClassOverview: async ({
    urlParams,
    params,
  }: ConfigWithURLParams): Promise<PaginatedResponse<ClassOverview[]>> => {
    try {
      const res = await axios.get(urls.getClassOverview(urlParams.classId), {
        params,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  markAttendance: async ({
    urlParams,
    data,
  }: ConfigWithURLParams): Promise<APIResponse<MarkAttendanceResponse>> => {
    try {
      const res = await axios.put(urls.markAttendance(urlParams.id), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
