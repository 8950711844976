import axios from "api/axios"
import { APIResponse } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Course } from "./types"
import { urls } from "./urls"

export default {
  list: async (): Promise<APIResponse<{ courses: Course[] }>> => {
    try {
      const res = await axios.get(urls.list())
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
