import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { AuthData, RefreshTokenResponse } from "./types"
import { urls } from "./urls"

export default {
  generatePlatformToken: async ({
    data,
  }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.auth.generatePlatformToken(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  refreshToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<RefreshTokenResponse>> => {
    try {
      const res = await axios.post(urls.auth.refreshToken(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  verifyAccessToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<{ verified: boolean }>> => {
    try {
      const res = await axios.post(urls.auth.verifyAccessToken(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
