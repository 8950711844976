import Navbar from "components/Navbar"
import PageContainer from "components/PageContainer"

import ClassesCalendar from "./Calendar"
import ClassesTable from "./LectureList"

const Classes = () => {
  return (
    <>
      <Navbar hideBackButton />
      <PageContainer>
        <ClassesCalendar />
        <div className="mt-2">
          <ClassesTable />
        </div>
      </PageContainer>
    </>
  )
}

export default Classes
