export const getFroalaConfig = (props?: { disabled?: boolean }) => ({
  key: import.meta.env.VITE_FROALA_EDITOR_API_KEY,
  attribution: false,
  heightMin: "380px",
  wordCounterCount: false,
  imagePaste: true,
  imageDefaultDisplay: "inline",
  imagePasteProcess: true,
  charCounterCount: false,
  quickInsertEnabled: false,
  tabSpaces: 4,
  fontFamily: {
    "sans-serif": "sans-serif",
  },
  fontFamilyDefaultSelection: "sans-serif",
  fontSizeDefaultSelection: 18,
  fontFamilySelection: false,
  htmlAllowedTags: [
    "a",
    "b",
    "bdi",
    "bdo",
    "br",
    "caption",
    "code",
    "col",
    "colgroup",
    "dd",
    "dfn",
    "dl",
    "dt",
    "em",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hr",
    "i",
    "img",
    "li",
    "ol",
    "p",
    "pre",
    "s",
    "samp",
    "small",
    "strike",
    "strong",
    "sub",
    "sup",
    "table",
    "tbody",
    "td",
    "tfoot",
    "th",
    "thead",
    "tr",
    "u",
    "ul",
    "wbr",
  ],
  toolbarButtons: {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "inlineStyle",
        "clearFormatting",
      ],
      buttonsVisible: 3,
    },
    moreParagraph: {
      buttons: [
        "alignLeft",
        "alignCenter",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",
        "quote",
      ],
    },
    moreRich: {
      buttons: ["insertTable", "insertLink", "insertImage", "insertHR"],
      buttonsVisible: 1,
    },
    moreMisc: {
      buttons: ["undo", "redo"],
      align: "right",
      buttonsVisible: 2,
    },
  },
  events: {
    initialized() {
      // eslint-disable-next-line
      const editor: any = this
      if (props?.disabled) {
        editor.edit.off()
      }
    },
    // Copied from https://wysiwyg-editor.froala.help/hc/en-us/articles/115000555949-Can-I-insert-images-as-base64-
    "image.beforeUpload": function (files: File[]) {
      // eslint-disable-next-line
      const editor: any = this
      editor.edit.off()
      if (files.length) {
        const currentImage = editor.image.get()

        // Create a File Reader.
        const reader = new FileReader()

        // Set the reader to insert images when they are loaded.
        reader.onload = function (e) {
          const result = e.target?.result
          editor.image.insert(result, null, null, currentImage)
        }

        // Read image as base64.
        reader.readAsDataURL(files[0])
      }

      editor.popups.hideAll()

      // Stop default upload chain.
      return false
    },
  },
})
