import {
  Avatar,
  Button,
  CircularProgress,
  cn,
  Switch,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useCopyToClipboard } from "@uidotdev/usehooks"
import api from "api"
import Navbar from "components/Navbar"
import PageContainer from "components/PageContainer"
import BackButton from "components/shared/BackButton"
import { Attachment, Check, Copy, InfoCircle, ViewGrid } from "iconoir-react"
import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { formatDate } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import Editor from "../Editor"
import { formatAssignmentTitle } from "../utils"
import PlagiarisedSubmissionView from "./PlagiarisedSubmission"
import RubricSheet from "./RubricSheet"

type SelectedRubric = { rubricId: string; commentId: string }

export type CurrentReview = {
  comment: string
} & {
  selected: SelectedRubric[]
}

const ViewSubmission = () => {
  const params = useParams() as { id: string }
  const initId = params.id

  const [id, setId] = useState(initId)

  const [showRubrics, setShowRubrics] = useState(false)
  const [showPlagiarisedSubmission, setShowPlagiarisedSubmission] =
    useState(false)

  const selectedRubrics = useArray<SelectedRubric>([])
  const [currentReview, setCurrentReview] = useState<
    Omit<CurrentReview, "selected">
  >({
    comment: "",
  })

  const {
    isFetching: overviewLoading,
    data: overviewData,
    refetch: refreshOverview,
  } = useQuery({
    queryKey: ["submission", id],
    queryFn: () =>
      api.submissions.retrieve({
        urlParams: {
          submissionId: id,
        },
        params: {
          fields: [
            "review",
            "iqa_review",
            "review_rubrics",
            "iqa_review_rubrics",
            "submitted_at",
            "citations",
          ],
        },
      }),
  })

  const { data: submissionData, refetch: refetchSubmissionData } = useQuery({
    queryKey: [
      "assignment-user-submissions-list",
      overviewData?.assignmentId,
      overviewData?.userId,
    ],
    queryFn: () =>
      api.submissions.listAssignmentUserSubmissions({
        urlParams: {
          assignmentId: overviewData?.assignmentId || "",
          userId: overviewData?.userId || "",
        },
        params: {
          page: "all",
        },
      }),
    enabled: () => !!(overviewData?.assignmentId && overviewData?.userId),
  })

  const { data: rubricsData, refetch: refetchRubrics } = useQuery({
    queryKey: ["assignment-rubrics", overviewData?.assignmentId],
    queryFn: () =>
      api.review.rubrics.list({
        urlParams: {
          assignmentId: overviewData?.assignmentId || "",
        },
        params: {
          page: "all",
        },
      }),
    enabled: () => !!overviewData?.assignmentId,
  })

  const { data: plagiarismData } = useQuery({
    queryKey: ["plagiarism-report", id],
    queryFn: () =>
      api.submissions.getPlagiarismReport({
        urlParams: {
          submissionId: id,
        },
      }),
  })
  const isPlagiarised = plagiarismData?.plagiarismDetected

  const plagiarismPercentage = plagiarismData?.overallPlagiarismScore

  const rubrics = rubricsData
    ? rubricsData.map(r => {
        const review =
          overviewData?.reviewRubrics?.find(review => r.id === review.id) || {}
        return { ...r, ...review }
      })
    : []

  const { data: suggestedRubrics } = useQuery({
    queryKey: ["assignment-rubrics-ai-suggestions", initId],
    queryFn: () =>
      api.review.rubrics.getAISuggestions({
        urlParams: {
          submissionId: initId || "",
        },
      }),
    enabled: showRubrics,
  })

  const queryClient = useQueryClient()

  const onCopyFromPrevious = async () => {
    const previousSubmissionId = submissionData && submissionData[1]?.uuid
    if (!previousSubmissionId) {
      return
    }

    try {
      const data = await queryClient.fetchQuery({
        queryKey: ["submission", previousSubmissionId],
        queryFn: () =>
          api.submissions.retrieve({
            urlParams: {
              submissionId: previousSubmissionId,
            },
            params: {
              fields: ["review_rubrics", "review"],
            },
          }),
      })

      if (data.reviewRubrics) {
        selectedRubrics.set(
          data.reviewRubrics.map(r => ({
            commentId:
              r.allComments.find(c => c.uuid === r.marked?.uuid)?.uuid || "",
            rubricId: r.id,
          }))
        )
      }
      if (data.review) {
        setCurrentReview(d => ({
          ...d,
          comment: data.review?.comments || "",
        }))
      }
    } catch (error) {}
  }

  const onCellClick = (rubricId: string, commentId: string) => {
    const data = selectedRubrics.array
    if (data.find(s => s.rubricId === rubricId)) {
      if (
        data.find(s => s.rubricId === rubricId && s.commentId === commentId)
      ) {
        selectedRubrics.removeByKey(commentId, "commentId")
      } else {
        selectedRubrics.updateByKey(
          rubricId,
          { commentId, rubricId },
          "rubricId"
        )
      }
    } else {
      selectedRubrics.push({
        commentId,
        rubricId,
      })
    }
  }

  const onCommentUpdate = (comment: string) => {
    setCurrentReview(d => ({
      ...d,
      comment: comment,
    }))
  }

  const aiGeneratedLevel = (() => {
    if (!plagiarismData?.probableAiWritten) return null

    const confidence = Math.round(parseFloat(plagiarismData?.probableAiWritten))

    if (!confidence) return null

    if (confidence <= 50) return "low"
    if (confidence <= 80) return "medium"
    if (confidence <= 100) return "high"

    return null
  })()

  const [copiedText, copyToClipboard] = useCopyToClipboard()

  const copySubmissionToClipboard = () => {
    const text = document.querySelector("#submission-text")
    if (text) copyToClipboard(text.textContent || "")
  }

  return (
    <div className="min-h-screen pb-4">
      {rubrics && (
        <RubricSheet
          open={showRubrics}
          onClose={() => setShowRubrics(false)}
          data={overviewData}
          // @ts-expect-error to be fixed
          rubrics={rubrics}
          setId={setId}
          submissionData={submissionData}
          submissionId={id}
          onCopyFromPrevious={onCopyFromPrevious}
          onCellClick={onCellClick}
          suggestedRubrics={suggestedRubrics}
          currentReview={{
            ...currentReview,
            selected: selectedRubrics.array,
          }}
          onCommentUpdate={onCommentUpdate}
          refresh={async () => {
            await refetchSubmissionData()
            await refreshOverview()
            await refetchRubrics()
          }}
        />
      )}
      <Navbar gutterBottom={false} hideBackButton />
      {isPlagiarised && plagiarismPercentage && (
        <div className="bg-warning-200">
          <PageContainer>
            <div className="flex flex-col items-center justify-between gap-0.5 py-1 md:flex-row md:py-1.5">
              <Typography className="flex items-center gap-1 text-sm text-warning-800">
                <div>
                  <InfoCircle className="size-2.25" />
                </div>
                This submission seems to be plagiarized. Please evaluate the
                assignment accordingly
              </Typography>
              <Typography variant="strong" className="text-warning-900">
                Overall Plagiarism: {Number(plagiarismPercentage).toFixed(0)}%
              </Typography>
            </div>
          </PageContainer>
        </div>
      )}
      {aiGeneratedLevel && (
        <div
          className={cn({
            "text-[#1e3a8a] bg-[#dbeafe]": aiGeneratedLevel === "low",
            "text-[#544103] bg-[#fcf3d8]": aiGeneratedLevel === "medium",
            "text-[#4a1616] bg-[#f7e1e1]": aiGeneratedLevel === "high",
          })}
        >
          <PageContainer>
            <div className="flex flex-col gap-0.5 py-0.5">
              <Typography>
                Our system has detected a <strong>{aiGeneratedLevel} </strong>
                probability that this submission was generated using AI.
              </Typography>
              <Typography variant="subtitle2">
                You may choose to take action based on this information
              </Typography>
            </div>
          </PageContainer>
        </div>
      )}
      <PageContainer>
        <div className="mt-2 flex flex-col gap-2">
          <BackButton />
          {overviewData && (
            <>
              <div className="flex items-center justify-between gap-1">
                <div className="flex items-center gap-1">
                  <Avatar
                    src={overviewData.user.photo}
                    name={overviewData.user.fullName}
                    className="size-5"
                  />
                  <div className="flex flex-col">
                    <Typography variant="title3">
                      {overviewData.user.fullName}
                    </Typography>
                    <div>
                      <Typography
                        variant="smallBody"
                        className="text-secondary-500"
                      >
                        {overviewData.user.email}
                      </Typography>
                    </div>
                  </div>
                </div>
                {overviewData.submittedAt && (
                  <div>
                    <Typography
                      variant="smallBody"
                      className="text-secondary-500"
                    >
                      Submitted on {formatDate(overviewData.submittedAt)}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1.5 overflow-x-auto rounded-xl border border-onSurface-300 p-2">
                <Typography variant="title4">
                  {formatAssignmentTitle(overviewData.assignment)}
                </Typography>
                <Editor value={overviewData.assignment.instructions} viewOnly />
              </div>
              <div className="flex flex-col gap-1.5 overflow-x-auto rounded-xl border border-onSurface-300 p-2">
                <div className="flex justify-between">
                  <div className="flex items-center gap-1">
                    <Typography variant="title4">
                      Assignment Submission
                    </Typography>

                    <Button
                      startAdornment={copiedText ? <Check /> : <Copy />}
                      variant="text"
                      size="sm"
                      onClick={copySubmissionToClipboard}
                      color={copiedText ? "success" : "primary"}
                      className="px-0.5 py-0.25"
                    >
                      {copiedText ? "Copied" : "Copy Submission"}
                    </Button>
                  </div>
                  {isPlagiarised && plagiarismData?.updatedHtml && (
                    <div className="flex items-center gap-1">
                      <Typography variant="smallBody">
                        Highlight Plagiarism
                      </Typography>{" "}
                      <Switch
                        size="xs"
                        checked={showPlagiarisedSubmission}
                        onCheckedChange={setShowPlagiarisedSubmission}
                      />
                    </div>
                  )}
                </div>
                {showPlagiarisedSubmission && plagiarismData ? (
                  <PlagiarisedSubmissionView
                    data={plagiarismData}
                    submissionId={id}
                  />
                ) : (
                  <Typography
                    id="submission-text"
                    variant="body"
                    dangerouslySetInnerHTML={{
                      __html: overviewData.submission || "",
                    }}
                  />
                )}
                {overviewData.attachments.length > 0 && (
                  <>
                    <hr />
                    <div>
                      {overviewData.attachments.map((a, i) => (
                        <div key={i} className="flex">
                          <Link to={a.file} target="_blank" className="flex">
                            <div className="flex items-center gap-1">
                              <div className="rounded border p-1">
                                <Attachment className="size-2.5" />
                              </div>
                              <Typography variant="strongSmallBody">
                                {a.name}
                              </Typography>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              {isPlagiarised && plagiarismData?.plagiarisedSourcesUsers && (
                <div className="flex flex-col gap-1.5 overflow-x-auto rounded-xl border border-onSurface-300 p-2">
                  <Typography variant="title4">
                    Plagiarised Sources (
                    {plagiarismData.plagiarisedSourcesUsers.length})
                  </Typography>
                  <div className="flex flex-col gap-1">
                    {plagiarismData.plagiarisedSourcesUsers.map((e, i) => {
                      return (
                        <div key={i} className="flex items-center">
                          <Avatar
                            name={`${Number(i + 1)
                              .toString()
                              .split("")
                              .join(" ")}`}
                            className="size-4 rounded-full"
                          />
                          <Typography>{e.user.fullName}</Typography>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              {overviewData.references && (
                <div className="flex flex-col gap-1.5 overflow-x-auto rounded-xl border border-onSurface-300 p-2">
                  <Typography variant="title4">References</Typography>
                  <Typography
                    variant="body"
                    className="break-words [&_a:hover]:underline [&_a]:text-interactive-500"
                    dangerouslySetInnerHTML={{
                      __html: overviewData.references,
                    }}
                  />
                </div>
              )}
            </>
          )}
          {!showRubrics && overviewData && rubrics?.length > 0 && (
            <div className="fixed bottom-2 right-2  cursor-pointer rounded-full">
              <Button
                startAdornment={<ViewGrid />}
                onClick={() => setShowRubrics(true)}
                size="lg"
                rounded
                className="shadow-lg"
              >
                View Rubrics
              </Button>
            </div>
          )}
        </div>
        {overviewLoading && (
          <div className="my-2 flex justify-center">
            <CircularProgress className="size-4" />
          </div>
        )}
      </PageContainer>
    </div>
  )
}

export default ViewSubmission
