import { TextField } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Search, Xmark } from "iconoir-react"
import debounce from "lodash/debounce"
import React, { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"

import styles from "./SearchField.module.css"

type Props = {
  className?: string
  placeholder?: string
  useSearchStyles?: boolean
  useSearchParam?: boolean
  paramKey?: string
  isClearable?: boolean
}

const useSearchField = ({
  className,
  placeholder,
  useSearchStyles,
  useSearchParam,
  paramKey = "search",
  isClearable = true,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState(
    useSearchParam ? searchParams.get(paramKey) || "" : ""
  )

  const ref = useRef<HTMLInputElement>(null)

  const handleSearch = debounce(function (value: string) {
    setSearch(value)
    if (useSearchParam) {
      setSearchParams(
        p => {
          if (value.length > 0) {
            p.set(paramKey, value)
          } else {
            p.delete(paramKey)
          }
          return p
        },
        { replace: true }
      )
    }
  }, 750)

  const reset = () => {
    setSearch("")
    if (ref.current) {
      ref.current.value = ""
    }
    if (useSearchParam) {
      setSearchParams(p => {
        p.delete(paramKey)
        return p
      })
    }
  }

  useEffect(() => {
    if (ref.current && search) {
      ref.current.value = search
    }
  }, [ref.current])

  const SearchField = (
    <TextField
      className={clsx(
        className,
        useSearchStyles && styles.searchInput,
        "!text-onSurface-500"
        // "bg-inherit transition-colors duration-200",
        // "[&>div:focus-within]:border-interactive-500 [&>div:hover]:border-black [&>div]:border-b [&>div]:border-surface-300"
      )}
      inputClassName="text-base"
      ref={ref}
      name="search"
      placeholder={placeholder || "Search by name"}
      startIcon={<Search className="size-2" />}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleSearch(e.target.value)
      }
      endIcon={
        isClearable && search.length > 0 ? (
          <Xmark
            className="size-2.5 text-onSurface-400 hover:cursor-pointer"
            onClick={reset}
          />
        ) : null
      }
    />
  )

  return { SearchField, searchText: search, reset }
}

export default useSearchField
