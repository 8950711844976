import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getHadesURL = getServiceURL(ServicePrefix.hades)

export const urls = validateUrls({
  review: {
    create: () => `/v1/assignments/reviews/`,
    detail: reviewId => `/assignments/reviews/${reviewId}/`,
    generateAIFeedback: id =>
      getHadesURL(`/v1/submission-feedback/${id}/suggestions/`),

    createReviewRubrics: () => `/assignments/review-rubrics/create/`,
  },

  rubrics: {
    list: assignmentId => `/assignments/${assignmentId}/list/rubrics/`,
    getAISuggestions: submissionId =>
      getHadesURL(
        `/v1/submission-grading/suggested-review-rubrics/${submissionId}/`
      ),
  },
})
