export const formatAssignmentTitle = ({
  title,
  shortTitle,
}: {
  title: string
  shortTitle: string | null
}) => {
  if (!shortTitle || shortTitle === title) return title
  return `${shortTitle}: ${title}`
}
