import {
  Button,
  CircularProgress,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Progress,
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  TextArea,
  toast,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { AISuggestedRubric, ReviewRubric } from "api/resources/review/types"
import {
  PreviousSubmissionListItem,
  Submission,
} from "api/resources/submissions/types"
import { AISparkleFilled } from "assets/icons/AISparkle"
import GenerateComment from "assets/icons/GenerateComment.svg"
import AsyncBuilder from "components/AsyncBuilder"
import { Copy, NavArrowDown } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { useState } from "react"
import { formatDate, handleErrors, pluralize, SetState } from "utils/helpers"

import StatusPill from "../../../components/ReviewStatusTag"
import { CurrentReview } from "."
import RejectSubmission from "./RejectSubmission"
import SubmitReview from "./SubmitReview"

type Props = {
  onClose: () => void
  open: boolean
  data: Submission | undefined
  setId: SetState<string>
  submissionData: PreviousSubmissionListItem[] | undefined
  submissionId: string
  rubrics: ReviewRubric[]
  refresh: () => Promise<any>
  onCopyFromPrevious: () => Promise<void>
  onCellClick: (rubricId: string, cellId: string) => void
  onCommentUpdate: (comment: string) => void
  currentReview: CurrentReview
  suggestedRubrics: AISuggestedRubric[] | undefined
}

const RubricSheet = ({
  onClose,
  open,
  data,
  setId,
  submissionData,
  submissionId,
  rubrics,
  onCopyFromPrevious,
  onCellClick,
  currentReview,
  onCommentUpdate,
  refresh,
  suggestedRubrics,
}: Props) => {
  const { review } = data || { review: undefined }
  const [showReject, setShowReject] = useState(false)
  const [showAccept, setShowAccept] = useState(false)
  const [generatingComments, setGeneratingComments] = useState(false)
  const [disableSelectRubrics, setDisableSelectRubrics] = useState(false)

  const onGenerateComments = async () => {
    setDisableSelectRubrics(true)
    setGeneratingComments(true)
    const rubricsData = rubrics.map(r => {
      const selectedCommentId = currentReview.selected.find(
        cr => cr.rubricId === r.id
      )
      const selectedComment = r.allComments.find(
        r => r.uuid === selectedCommentId?.commentId
      )

      return {
        criterion: r.criteria,
        score: Number(selectedComment?.scale),
        rubric_cells: r.allComments.map(r => ({
          comment: r.comments,
          scale: r.scale,
        })),
      }
    })

    try {
      const res = await api.review.generateAIFeedback({
        urlParams: {
          submissionId,
        },
        data: {
          rubrics: rubricsData,
        },
      })
      if (res.feedback) {
        onCommentUpdate(res.feedback)
      }
      console.log(res)
    } catch (error) {
      handleErrors(error)
    }
    setGeneratingComments(false)
    setDisableSelectRubrics(false)
  }

  const previousSubmissionId = submissionData && submissionData[1]?.uuid
  const isUnderReview = review?.status === "under_review"
  const isNotReviewed = data && data.status === "not_reviewed"

  const canAccept =
    isUnderReview &&
    currentReview.comment &&
    currentReview.selected.length === rubrics.length

  const canReject = isUnderReview && currentReview.comment

  const onAcceptSubmission = async () => {
    if (!submissionData) {
      setShowAccept(false)
      return
    }

    if (currentReview.comment.length < 4) {
      toast.error("Comments should have minimum 4 characters")
      return
    }

    setDisableSelectRubrics(true)
    try {
      const data = {
        professor_comments: currentReview.comment,
        review: submissionData[0].review?.uuid,
        rubric_cells: currentReview.selected.map(r => r.commentId),
      }
      await api.review.createReviewRubrics({
        data,
      })
      await refresh()
    } catch (error) {
      handleErrors(error)
    }
    setDisableSelectRubrics(false)
    setShowAccept(false)
  }

  const onStartReview = async () => {
    if (!data) {
      return
    }
    try {
      const payload = {
        assignment_id: data.assignmentId,
        submission_id: data.uuid,
        user_id: data.userId,
      }

      await api.review.create({
        data: payload,
      })
      await refresh()
    } catch (error) {
      handleErrors(error)
    }
  }

  const onReject = async (isPlagiarised: boolean) => {
    if (!review?.uuid) {
      return
    }

    if (currentReview.comment.length < 4) {
      toast.error("Comments should have minimum 4 characters")
      return
    }

    setDisableSelectRubrics(true)
    try {
      await api.review.update({
        urlParams: {
          reviewId: review.uuid,
        },
        data: {
          comments: currentReview.comment,
          status: isPlagiarised ? "plagiarised" : "rejected",
        },
      })

      await refresh()
      setShowReject(false)
    } catch (error) {
      handleErrors(error)
    }
    setDisableSelectRubrics(false)
  }

  const isAiSuggested = (commentId: string) => {
    return (
      isUnderReview && !!suggestedRubrics?.find(r => r.rubricCell === commentId)
    )
  }

  return (
    <>
      <RejectSubmission
        open={showReject}
        onClose={() => setShowReject(false)}
        onReject={onReject}
      />
      <SubmitReview
        open={showAccept}
        onClose={() => setShowAccept(false)}
        onAccept={onAcceptSubmission}
      />
      <Sheet
        open={open}
        onOpenChange={open => {
          if (!open) {
            onClose()
          }
        }}
      >
        <SheetContent
          height={90}
          side="right"
          className="w-screen md:w-3/4 md:!max-w-none"
          closeWhenInteractOutside
        >
          <SheetHeader>
            <div className="mr-2 flex w-full flex-wrap items-center justify-between">
              <div>
                <div className="mb-0.5 flex gap-1">
                  {submissionData && (
                    <div>
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Typography
                            variant="strong"
                            className="flex items-center gap-0.5"
                          >
                            Previous Submissions <NavArrowDown />
                          </Typography>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuGroup>
                            {submissionData
                              .sort((a, b) => b.order - a.order)
                              .map((s, i) => (
                                <DropdownMenuItem
                                  key={i}
                                  onClick={() => setId(s.uuid)}
                                  className={cn(
                                    submissionId === s.uuid &&
                                      "bg-primary-500 text-surface focus:!bg-primary-500 focus:!text-surface"
                                  )}
                                >
                                  <div>
                                    <Typography>
                                      Submission {submissionData.length - i}{" "}
                                      {submissionData.length - i ===
                                        submissionData.length && "(Latest)"}
                                    </Typography>
                                    <Typography variant="smallBody">
                                      Score:{" "}
                                      {s.marks !== null
                                        ? s.marks.toFixed(2)
                                        : "Not yet scored"}
                                    </Typography>
                                  </div>
                                </DropdownMenuItem>
                              ))}
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col md:flex-row md:items-center md:gap-1">
                    <Typography>
                      Score:{" "}
                      {review?.marks
                        ? Number(review.marks).toFixed(2)
                        : "Not yet scored"}
                    </Typography>
                    <div className="hidden h-full w-0.25 bg-onSurface-500 md:block">
                      &nbsp;
                    </div>
                    <div className="flex items-center gap-0.5">
                      <Typography>Status:</Typography>
                      <StatusPill
                        result={review?.result}
                        status={review?.status}
                        order={undefined}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 md:flex-row md:items-center">
                    <Typography>
                      Reviewer:{" "}
                      {review?.professor ? review.professor : "Not available"}
                    </Typography>
                    {review?.datePublished && (
                      <>
                        <div className="hidden h-full w-0.25 bg-onSurface-500 md:block">
                          &nbsp;
                        </div>
                        <Typography>
                          {formatDate(review.datePublished, "MMM dd, yyyy")}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {isNotReviewed && (
                  <AsyncBuilder
                    handler={onStartReview}
                    render={({ loading, onClick }) => (
                      <Button
                        loading={loading}
                        onClick={onClick}
                        size="sm"
                        disabled={!data}
                      >
                        Start Review
                      </Button>
                    )}
                  />
                )}
              </div>
            </div>
          </SheetHeader>
          <SheetBody className="flex flex-col gap-3">
            {rubrics
              ?.sort((a, b) =>
                `${a.task} ${a.criteria}` > `${b.task} ${b.criteria}` ? 1 : -1
              )
              .map(({ task, criteria, allComments, marked, id }, i) => {
                return (
                  <div
                    key={i}
                    className={cn(
                      "flex flex-col gap-1.5",
                      isUnderReview && "mb-0.5"
                    )}
                  >
                    <Typography variant="strong">
                      {task}) {capitalize(criteria)}
                    </Typography>
                    <div className="grid gap-2 md:grid-cols-3">
                      {allComments
                        .sort((a, b) => a.scale - b.scale)
                        .map(({ comments, scale, uuid }, index) => {
                          const isMarked =
                            marked?.uuid === uuid ||
                            !!currentReview.selected.find(
                              s => s.commentId === uuid
                            )

                          return (
                            <div key={index} className={cn("relative")}>
                              <button
                                onClick={() => {
                                  if (isUnderReview) {
                                    onCellClick(id, uuid)
                                  }
                                }}
                                disabled={
                                  !isUnderReview || disableSelectRubrics
                                }
                                className={cn(
                                  isAiSuggested(uuid) &&
                                    "border border-[#39affd] ",
                                  "group flex text-start rounded-lg flex-grow h-full w-full"
                                )}
                              >
                                <div
                                  className={cn(
                                    "h-full flex flex-col grow gap-1 rounded-lg border p-2",
                                    {
                                      "bg-primary-500 text-surface": isMarked,
                                    }
                                  )}
                                >
                                  <Typography variant="title4">
                                    {pluralize("Point", scale)}
                                  </Typography>
                                  <Typography
                                    variant="smallBody"
                                    className={cn({
                                      "text-onSurface-800": !isMarked,
                                      "text-surface": isMarked,
                                    })}
                                  >
                                    {comments}
                                  </Typography>
                                </div>
                              </button>
                              {isAiSuggested(uuid) && (
                                <div className="absolute -bottom-1.25 flex w-full items-center justify-center">
                                  <div className="-mt-0.25 flex items-center justify-center rounded-xl bg-[#39affd] px-2 text-xs text-surface">
                                    <AISparkleFilled
                                      className="mr-0.5 h-full p-0.25"
                                      viewBox="0 0 20 20"
                                    />
                                    AI Suggested
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
            {review ? (
              <div className="flex flex-col gap-1">
                <Typography variant="strong">
                  Comments for this submission
                </Typography>
                <div className="relative">
                  {generatingComments && (
                    <div className="absolute z-20 size-full rounded-md border border-onSurface-800 bg-surface">
                      <div className="flex h-full flex-col items-center justify-center gap-1">
                        <div className="flex flex-col gap-0.5 text-center">
                          <Typography variant="title3">
                            Generating Comments
                          </Typography>
                          <Typography
                            variant="body"
                            className="text-onSurface-500"
                          >
                            This might take a minute
                          </Typography>
                        </div>
                        <div className="mt-1 w-[220px] md:w-full md:max-w-sm">
                          <Progress indeterminate />
                        </div>
                      </div>
                    </div>
                  )}
                  {isUnderReview && (
                    <Button
                      className="group absolute bottom-1 right-1 z-10  flex size-6 cursor-pointer items-center justify-between gap-1 rounded-full bg-onSurface-900 p-1 shadow-md transition-all duration-500 ease-in-out hover:aspect-auto hover:w-[190px] hover:bg-onSurface-900"
                      onClick={onGenerateComments}
                      disabled={
                        !(currentReview.selected.length === rubrics.length)
                      }
                    >
                      <div className="hidden duration-75 ease-in-out group-hover:relative group-hover:block group-hover:w-auto group-hover:scale-100 group-hover:opacity-100">
                        <Typography
                          className="absolute -z-10 hidden whitespace-nowrap text-onSurface-50 opacity-0 transition-opacity duration-1000 ease-linear  group-hover:relative group-hover:block group-hover:opacity-100"
                          variant="smallBody"
                        >
                          Generate Comments
                        </Typography>
                      </div>

                      <img
                        src={GenerateComment}
                        alt="GenerateComment"
                        className="mr-1 size-3.5 group-hover:mr-0"
                      />
                    </Button>
                  )}

                  <TextArea
                    value={
                      isUnderReview ? currentReview.comment : review?.comments
                    }
                    minLength={4}
                    key={review?.uuid}
                    onChange={e => onCommentUpdate(e.target.value)}
                    rows={8}
                  />
                </div>
                <div className="flex flex-wrap items-center justify-between gap-0.5 md:flex-nowrap md:gap-0">
                  <div>
                    <AsyncBuilder
                      handler={onCopyFromPrevious}
                      render={({ loading, onClick }) => (
                        <Button
                          onClick={onClick}
                          variant="text"
                          startAdornment={<Copy className="size-2.5" />}
                          disabled={!previousSubmissionId || !isUnderReview}
                          loading={loading}
                        >
                          Copy previous review
                        </Button>
                      )}
                    />
                  </div>
                  <div className="flex w-full items-center justify-between gap-1 md:w-auto">
                    <Tooltip content="Please add comments" enabled={!canReject}>
                      <Button
                        variant="text"
                        color="critical"
                        disabled={!canReject}
                        onClick={() => setShowReject(true)}
                      >
                        Reject Submission
                      </Button>
                    </Tooltip>

                    <Tooltip
                      content="Please mark all rubrics and add comments"
                      enabled={!canAccept}
                    >
                      <Button
                        size="sm"
                        disabled={!canAccept}
                        onClick={() => setShowAccept(true)}
                      >
                        Submit Review
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <Typography variant="title3" className="mb-1">
                Press the Start Review button to start reviewing this
                submission.
              </Typography>
            )}
          </SheetBody>
        </SheetContent>
      </Sheet>
    </>
  )
}

export default RubricSheet
