import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

type Props = {
  title: string
  count: number
  color: string
}

const OverviewStatCard = ({ title, count = 0, color }: Props) => {
  return (
    <div className="flex w-full flex-col justify-between rounded-lg border p-2 shadow-sm">
      <div>
        <Typography variant="body" className="text-onSurface-500">
          {title}
        </Typography>
      </div>
      <div>
        <div className="flex w-full flex-row items-center justify-between">
          <Typography variant="title2">{count}</Typography>
          <div
            className={clsx(
              "m-1 flex items-center justify-center rounded-full bg-opacity-15 p-1",
              color
            )}
          >
            <div className={clsx("size-1.75 rounded-full", color)} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default OverviewStatCard
