import { ToastProvider } from "@suraasa/placebo-ui"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import GlobalState from "components/GlobalState"
import { PageWithMeta } from "components/Metadata"
import PrivateRoute from "components/PrivateRoute"
import ViewStudent from "features/Assignments/Assignment/ViewStudent"
import ViewSubmission from "features/Assignments/Check"
import ViewAssignment from "features/Assignments/ViewAssignment"
import SSO from "features/auth/SSO"
import Home from "features/home"
import ListStudentsOrAssignments, {
  Type,
} from "features/home/ListStudentsOrAssignments"
import Lectures from "features/Lectures"
import ViewLecture from "features/Lectures/ViewLecture"
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom"
import DynamicRedirect from "utils/dynamicRedirects"
import { oldRoutes, routes } from "utils/routes"

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  })

  const router = createBrowserRouter([
    {
      path: routes.root,
      element: <SSO />,
    },
    {
      path: routes.root,
      element: <PrivateRoute />,
      children: [
        { path: routes.home, element: <Home /> },
        {
          path: routes.assignment.list,
          element: (
            <PageWithMeta name={"assignments"}>
              <ListStudentsOrAssignments type={Type.assignment} />
            </PageWithMeta>
          ),
        },
        {
          path: routes.students.list,
          element: (
            <PageWithMeta name={"students"}>
              <ListStudentsOrAssignments type={Type.student} />
            </PageWithMeta>
          ),
        },
        {
          path: routes.assignment.view,
          element: (
            <PageWithMeta name={"assignment"}>
              <ViewAssignment />
            </PageWithMeta>
          ),
        },
        {
          path: routes.students.view,
          element: (
            <PageWithMeta name={"student"}>
              <ViewStudent />
            </PageWithMeta>
          ),
        },
        {
          path: routes.submission.view,
          element: (
            <PageWithMeta name={"submission"}>
              <ViewSubmission />
            </PageWithMeta>
          ),
        },
        {
          path: routes.classes.home,
          element: (
            <PageWithMeta name={"classes"}>
              <Lectures />
            </PageWithMeta>
          ),
        },
        {
          path: routes.classes.class,
          element: (
            <PageWithMeta name={"lecture"}>
              <ViewLecture />
            </PageWithMeta>
          ),
        },
        {
          path: oldRoutes.dashboard,
          // old routes redirects
          element: <Outlet />,
          children: [
            {
              path: oldRoutes.home,
              element: <DynamicRedirect to={routes.assignment.list} />,
            },
            {
              path: oldRoutes.assignment.list,
              element: <DynamicRedirect to={routes.assignment.list} />,
            },
            {
              path: oldRoutes.assignment.view,
              element: <DynamicRedirect to={routes.assignment.view} />,
            },
            {
              path: oldRoutes.assignment.student,
              element: <DynamicRedirect to={routes.students.view} />,
            },
            {
              path: oldRoutes.students.list,
              element: <DynamicRedirect to={routes.students.list} />,
            },
            {
              path: oldRoutes.submission.view,
              element: <DynamicRedirect to={routes.submission.view} />,
            },
            {
              path: oldRoutes.classes.home,
              element: <DynamicRedirect to={routes.classes.home} />,
            },
            {
              path: oldRoutes.classes.class,
              element: <DynamicRedirect to={routes.classes.class} />,
            },
          ],
        },
      ],
    },
  ])

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalState>
        <ToastProvider />
        <RouterProvider router={router} />
      </GlobalState>
    </QueryClientProvider>
  )
}

export default App
