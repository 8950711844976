import { Select } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import Navbar from "components/Navbar"
import PageContainer from "components/PageContainer"
import { useNavigate } from "react-router-dom"
import useSearchField from "utils/hooks/useSearchField"
import { useSearchParamsHook } from "utils/hooks/useSearchParams"
import { routes } from "utils/routes"

import AssignmentsList from "./AssignmentsList"
import StudentsList from "./StudentsList"

export enum Type {
  student = "student",
  assignment = "assignment",
}

const viewModeOptions = [
  {
    label: "By Student",
    value: Type.student,
  },
  {
    label: "By Assignment",
    value: Type.assignment,
  },
]

type Props = {
  type: Type
}

const ListStudentsOrAssignments = ({ type }: Props) => {
  const navigate = useNavigate()
  const isByAssignment = type === Type.assignment

  const { searchParams, updateParam } = useSearchParamsHook({
    course: "",
  })

  const {
    SearchField,
    searchText,
    reset: resetSearch,
  } = useSearchField({
    className: "-mt-2",
    placeholder: isByAssignment
      ? "Search by assignment title..."
      : "Search by student name or email...",
    useSearchStyles: false,
    useSearchParam: true,
  })

  const courses = useQuery({
    queryFn: () => api.courses.list(),
    queryKey: ["courses"],
  })

  const selectedCourse = courses.data?.courses.find(
    c => c.uuid === searchParams.course
  )

  return (
    <>
      <Navbar hideBackButton />
      <PageContainer className="my-4">
        <div className="flex flex-col gap-1">
          {SearchField}

          <div className="flex gap-1">
            <Select
              options={courses.data?.courses}
              placeholder="Filter by course..."
              onChange={v => updateParam("course", v?.uuid)}
              value={selectedCourse}
              isLoading={courses.isLoading}
              isClearable
              getOptionLabel={option => option.name}
              getOptionValue={option => option.uuid}
            />

            <Select
              options={viewModeOptions}
              onChange={option => {
                if (!option) return

                resetSearch()

                if (option.value === Type.assignment) {
                  navigate(routes.assignment.list, { replace: true })
                }
                if (option.value === Type.student) {
                  navigate(routes.students.list, { replace: true })
                }
              }}
              value={viewModeOptions.find(v => v.value === type)}
              className="mb-2 max-w-xs"
            />
          </div>
        </div>

        {isByAssignment ? (
          <AssignmentsList
            courseId={selectedCourse?.uuid}
            search={searchText}
          />
        ) : (
          <StudentsList courseId={selectedCourse?.uuid} search={searchText} />
        )}
      </PageContainer>
    </>
  )
}

export default ListStudentsOrAssignments
