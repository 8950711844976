import { Navigate } from "react-router-dom"
import { routeBuilder } from "utils/helpers"
import { routes } from "utils/routes"

const Home = () => {
  return (
    <Navigate
      to={routeBuilder(routes.assignment.list, { type: "assignment" })}
      replace
    />
  )
}

export default Home
