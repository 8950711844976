import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getCelestialURL = getServiceURL(ServicePrefix.celestial)

export const urls = validateUrls({
  assignments: {
    list: () => getCelestialURL(`/v1/assignments/`),
    overview: assignmentId =>
      getCelestialURL(`/v1/assignments/${assignmentId}/overview/`),
  },

  users: {
    list: () => getCelestialURL("/v1/assignments/users/"),

    listSubmissionsByUserId: userId =>
      getCelestialURL(`/v1/assignments/users/${userId}/submissions/`),

    listSubmissionsByAssignmentId: assignmentId =>
      getCelestialURL(`/v1/assignments/${assignmentId}/submissions/`),

    overview: studentId =>
      getCelestialURL(`/v1/assignments/assignment-user/${studentId}/overview/`),
  },
})
