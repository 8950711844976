import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { routeBuilder } from "utils/helpers"
import { routes } from "utils/routes"

export type ClassEvent = {
  start?: Date | string | undefined
  end?: Date | string | undefined
  title?: Date | string | undefined | ReactNode
  resource?: {
    id: number
    startTime: string
    endTime: string
    classId: {
      id: number
      uuid: string
      name: string
    }
    course: string[]
    professor: string
    joinUrl: string
  }
}

export const ClassesDialog = ({
  classes,
  onClose,
}: {
  classes: ClassEvent[] | null | undefined
  onClose: () => void
}) => {
  const navigate = useNavigate()

  classes = classes || []

  return (
    <Dialog open={classes.length > 0} onOpenChange={() => onClose()}>
      <DialogContent closeWhenInteractOutside>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="title4">Classes</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-1 p-2">
          {classes.map(item => (
            <button
              onClick={() => {
                if (item.resource) {
                  navigate(
                    routeBuilder(routes.classes.class, {
                      id: String(item.resource.id),
                    })
                  )
                }
              }}
              className="flex h-3 w-full cursor-pointer  items-center rounded bg-success-500 p-0.25"
              key={item.resource?.id}
            >
              <Typography variant="smallBody" className="truncate text-white">
                {item.resource?.classId.name}
              </Typography>
            </button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}
