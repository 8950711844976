"use client"

import { cn } from "@suraasa/placebo-ui"
import { NavArrowLeft, NavArrowRight } from "iconoir-react"
import * as React from "react"
import { DayPicker } from "react-day-picker"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  startMonth = new Date(2016, 0),
  endMonth = new Date(),
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("", className, "w-[250px]")}
      classNames={{
        months: "flex flex-col gap-0.75",
        month: "w-full gap-2 flex flex-col",
        month_caption: "flex justify-center pt-1 gap-1 relative items-center",
        dropdowns: "flex flex-wrap gap-1",
        caption_label: "text-sm font-medium hidden",
        months_dropdown: "[&>span]:hidden border rounded",
        years_dropdown: "[&>span]:hidden border rounded",
        nav: "space-x-1 flex justify-between",

        month_grid: "w-full border-collapse space-y-1",
        weekdays: "flex",
        weekday:
          "text-muted-foreground rounded-md w-3 font-normal text-[0.8rem] w-full",
        week: "w-full mt-1 grid grid-cols-7",
        day: "w-full aspect-square text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 flex",
        day_button: cn(
          "w-auto aspect-square p-0 font-normal aria-selected:opacity-100"
        ),
        range_end: "day-range-end",
        selected:
          "bg-primary text-white hover:bg-primary rounded hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        today: "bg-accent text-accent-foreground",
        outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        disabled: "text-muted-foreground opacity-50",
        range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        hidden: "invisible",
        ...classNames,
      }}
      components={{
        Chevron: p => {
          if (p.orientation === "left") {
            return (
              <NavArrowLeft
                className={cn("size-3", p.disabled && "text-onSurface-300")}
              />
            )
          }
          if (p.orientation === "right") {
            return (
              <NavArrowRight
                className={cn("size-3", p.disabled && "text-onSurface-300")}
              />
            )
          }
          return <></>
        },
      }}
      captionLayout="dropdown"
      startMonth={startMonth}
      endMonth={endMonth}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
