import {
  Button,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import { format } from "date-fns"
import { Calendar as CalendarIcon, Xmark } from "iconoir-react"
import { useEffect, useState } from "react"

import { Calendar } from "./Calendar"

type Props = {
  placeholder: string
  disabled?: boolean
  closeOnSelect?: boolean
  onDateChange: (date: Date | undefined) => void
  value?: Date | undefined
}

export function DatePicker({
  placeholder,
  disabled,
  closeOnSelect = true,
  onDateChange,
  value,
}: Props) {
  const [open, setOpen] = useState(false)

  const [date, setDate] = useState<Date | undefined>(value)

  const onClear = (e: React.SyntheticEvent<SVGSVGElement>) => {
    e.stopPropagation()
    setDate(undefined)
  }

  const onSelect = (date: any) => {
    setDate(date)
    if (closeOnSelect) {
      setTimeout(() => {
        setOpen(false)
      }, 100)
    }
  }
  useEffect(() => {
    onDateChange(date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outlined"
          size="sm"
          className={cn(
            "w-full relative justify-start text-left font-normal !border !border-onSurface-800 focus-within:!ring-offset-1",
            !date && "text-muted-foreground"
          )}
          startAdornment={
            <CalendarIcon className="mr-0.5 size-2 text-onSurface-500" />
          }
        >
          <div className="flex w-full items-center justify-between">
            <Typography
              className={cn("shrink-0 text-muted-foreground text-[#acacac]", {
                "disabled:opacity-50": disabled,
              })}
            >
              {date ? format(date, "PPP") : placeholder}
            </Typography>
            {date ? (
              <Xmark
                className="absolute right-0.5 size-2.25 rounded-full bg-onSurface-100 text-onSurface-500 hover:rounded-md hover:bg-onSurface-200 sm:bg-white md:relative"
                onClick={onClear}
              />
            ) : null}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar mode="single" selected={date} onSelect={onSelect} />
      </PopoverContent>
    </Popover>
  )
}
