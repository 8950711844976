import {
  Button,
  CircularProgress,
  cn,
  Select,
  Table,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import api from "api"
import CoursesList from "components/CoursesList"
import Navbar from "components/Navbar"
import PageContainer from "components/PageContainer"
import BackButton from "components/shared/BackButton"
import TableWrapper from "components/TableWrapper"
import OverviewStatCard from "features/home/ListStudentsOrAssignments/OverviewStatCard"
import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { formatDate, routeBuilder } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import useSearchField from "utils/hooks/useSearchField"
import { useSearchParamsHook } from "utils/hooks/useSearchParams"
import { routes } from "utils/routes"

import StatusPill, {
  StatusOptions,
} from "../../../../components/ReviewStatusTag"
import { CoursesDialog } from "../../CoursesDialog"
import { formatAssignmentTitle } from "../../utils"

const ViewStudent = () => {
  const { id } = useParams() as { id: string }

  const [showCourses, setShowCourses] = useState<string[] | null>(null)

  const {
    searchParams: { course, status },
    updateParam,
  } = useSearchParamsHook({
    course: "",
    status: "",
  })

  const { SearchField, searchText: search } = useSearchField({
    placeholder: "Search assignment...",
    useSearchParam: true,
  })

  const { data: coursesData, isLoading: courseLoading } = useQuery({
    queryFn: () => api.courses.list(),
    queryKey: ["courses"],
  })

  const { isFetching: overviewLoading, data: overviewData } = useQuery({
    queryKey: ["assignment-user-overview", id],
    queryFn: () =>
      api.assignments.users.overview({
        urlParams: {
          studentId: id,
        },
      }),
  })
  const filterCourse = coursesData?.courses.find(c => c.uuid === course)

  const { isFetching, hasNextPage, fetchNextPage, data, isLoading } =
    useInfiniteQuery({
      queryKey: [
        "assignment-user-list-by-user-id",
        id,
        search,
        status,
        filterCourse,
      ],
      queryFn: x =>
        api.assignments.users.listAllUserSubmissionsByUserId({
          urlParams: {
            studentId: id,
          },
          params: {
            page: x.pageParam,
            search,
            status: status || null,
            course: filterCourse?.uuid || null,
            fields: "status",
          },
        }),
      initialPageParam: 1,
      getNextPageParam: lastPage => {
        return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
      },
    })

  const submissions =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })
  const totalRecords = data?.pages[0].data.total

  if (!overviewData || overviewLoading) {
    return (
      <>
        <Navbar />
        <PageContainer className="my-4">
          <div className="my-2 flex justify-center">
            <CircularProgress className="size-4" />
          </div>
        </PageContainer>
      </>
    )
  }
  const {
    totalAssignments,
    acceptedSubmissions,
    totalSubmissions,
    pendingReviews,
    user,
  } = overviewData

  return (
    <>
      <CoursesDialog
        courses={showCourses?.map(m => ({
          name: m,
        }))}
        onClose={() => setShowCourses(null)}
      />
      <Navbar gutterBottom={false} hideBackButton />
      <PageContainer className="border-b">
        <div className="flex justify-between py-2">
          <div>
            <Typography variant="title2">{user.fullName}</Typography>
            <Typography className="text-onSurface-700">{user.email}</Typography>
          </div>
          {/* <div>
            <Button size="sm">Start Checking</Button>
          </div> */}
        </div>
      </PageContainer>

      <PageContainer>
        <div className="mt-2 flex flex-col gap-2">
          <BackButton />

          <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-4">
            <OverviewStatCard
              color="bg-primary-300"
              count={totalAssignments}
              title="Total Assignments"
            />
            <OverviewStatCard
              color="bg-warning-300"
              count={totalSubmissions}
              title="Total Submissions"
            />
            <OverviewStatCard
              color="bg-success-300"
              count={acceptedSubmissions}
              title="Accepted Submissions"
            />
            <OverviewStatCard
              color="bg-critical-300"
              count={pendingReviews}
              title="Pending Reviews"
            />
          </div>

          <div className="flex flex-col gap-1 sm:flex-row">
            {SearchField}

            <Select
              options={coursesData?.courses}
              isLoading={courseLoading}
              placeholder="Course"
              onChange={v => updateParam("course", v?.uuid)}
              value={filterCourse || null}
              isClearable
              getOptionLabel={(option: { name: any }) => `${option.name}`}
              getOptionValue={option => (option ? option.uuid : "")}
            />

            <Select
              options={StatusOptions}
              placeholder="Status"
              isClearable
              onChange={option => {
                updateParam("status", option?.value)
              }}
              value={StatusOptions.find(v => v.value === status) || null}
              getOptionLabel={option => `${option.label}`}
              getOptionValue={option => option.value}
            />
          </div>
        </div>

        <TableWrapper
          initialLoading={isLoading && isFetching}
          addBottomPadding
          scrollLoading={!isLoading && isFetching && submissions.length > 0}
          showNoData={!isLoading && submissions.length === 0}
          content={className => (
            <Table
              className={cn(className, "mt-2")}
              data={submissions}
              headers={[
                {
                  title: "Assignment",
                  id: "assignment",
                  columnComponent: ({ row }) => (
                    <td>
                      <div ref={ref} className="min-w-[200px]">
                        <Typography variant="strong">
                          {formatAssignmentTitle(row.assignment)}
                        </Typography>
                      </div>
                    </td>
                  ),
                },
                {
                  title: "Courses",
                  id: "courses",
                  columnComponent: ({ row: { courses } }) => (
                    <td className="min-w-[200px]">
                      <CoursesList courses={courses} />
                    </td>
                  ),
                },
                {
                  title: "Status",
                  id: "status",
                  columnComponent: ({ row }) => (
                    <td>
                      <StatusPill
                        status={row.status}
                        result={row.review?.result}
                        order={row.order}
                      />
                    </td>
                  ),
                },
                {
                  title: "Reviewer",
                  id: "reviewer",
                  columnComponent: ({ row }) => (
                    <td>
                      <div>
                        <Typography>{row.review?.professor || `-`}</Typography>
                      </div>
                    </td>
                  ),
                },
                {
                  title: "Submissions",
                  id: "submissions",
                  columnComponent: ({ row }) => (
                    <td>
                      <Typography>{row.order || `-`}</Typography>
                    </td>
                  ),
                },
                {
                  title: "Date",
                  id: "date",
                  columnComponent: ({ row }) => (
                    <td>
                      <div className="min-w-12">
                        <Typography>
                          {row.submittedAt
                            ? formatDate(row.submittedAt, "MMM d, yyyy")
                            : "-"}
                        </Typography>
                      </div>
                    </td>
                  ),
                },

                {
                  title: totalRecords
                    ? `${submissions.length} of ${totalRecords} records`
                    : "",
                  id: "",
                  columnComponent: ({ row }) => (
                    <td>
                      <Link
                        to={routeBuilder(routes.submission.view, {
                          id: row.uuid,
                        })}
                      >
                        <Button variant="text">View Submission</Button>
                      </Link>
                    </td>
                  ),
                },
              ]}
              multiSort={false}
            />
          )}
        />
      </PageContainer>
    </>
  )
}

export default ViewStudent
