export default {
  classes: {
    title: "Classes",
  },
  assignments: {
    title: "Assignments",
  },
  students: {
    title: "Students",
  },
  assignment: {
    title: "Assignment",
  },
  student: {
    title: "Student",
  },
  submission: {
    title: "Submission",
  },
  lecture: {
    title: "Lecture",
  },
}
