import { Navigate, useParams } from "react-router-dom"

import { routeBuilder, RouteParams } from "./helpers"

interface DynamicRedirectProps<T extends string> {
  to: T // new route
}

const DynamicRedirect = <T extends string>({ to }: DynamicRedirectProps<T>) => {
  // params from old route
  const params = useParams() as RouteParams<T>

  const redirectUrl = routeBuilder(to, params)

  return <Navigate to={redirectUrl} replace />
}

export default DynamicRedirect
