import { User } from "api/resources/auth/types"
import React, { createContext, useContext, useState } from "react"

const defaultProfile: User = {
  email: "",
  emailVerified: false,
  firstName: "",
  hasPassword: false,
  lastName: "",
  middleName: "",
  phoneNumber: null,
  phoneNumberVerified: false,
  username: "",
  uuid: "",
}

export type Context = {
  profile: User
  setProfile: (profile: Partial<User>) => void
}

export const GlobalContext = createContext<Context>({
  setProfile: () => {},
  profile: defaultProfile,
})
export const useGlobalContext = () => useContext(GlobalContext)

const GlobalState = ({ children }: { children: React.ReactNode }) => {
  const [profile, setProfile] = useState<Context["profile"]>(defaultProfile)

  return (
    <GlobalContext.Provider
      value={{
        profile,
        setProfile: (newState: Partial<User>) => {
          setProfile(prevState => ({ ...prevState, ...newState }))
        },
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalState
