import { Avatar, Button, cn, Table, Typography } from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import TableWrapper from "components/TableWrapper"
import { Link } from "react-router-dom"
import { routeBuilder } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import { routes } from "utils/routes"

type Props = {
  search?: string
  courseId?: string
}

const StudentsList = ({ search, courseId }: Props) => {
  const { isFetching, hasNextPage, fetchNextPage, data, isLoading } =
    useInfiniteQuery({
      queryKey: ["students-list", search, courseId],
      queryFn: x =>
        api.assignments.users.list({
          params: {
            page: x.pageParam,
            search,
            courses: courseId || null,
          },
        }),
      initialPageParam: 1,
      getNextPageParam: lastPage =>
        lastPage.isSuccessful ? lastPage.data.nextPage : undefined,
    })

  const students =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  const totalRecords = data?.pages[0].data.total

  return (
    <TableWrapper
      initialLoading={isLoading && isFetching}
      scrollLoading={!isLoading && isFetching && students.length > 0}
      showNoData={!isLoading && students.length === 0}
      content={className => (
        <Table
          className={cn(className, "mt-2")}
          data={students}
          headers={[
            {
              title: "Learner",
              id: "name",
              columnComponent: ({ row }) => {
                const name = [row.firstName, row.middleName, row.lastName]
                  .filter(Boolean)
                  .join(" ")
                return (
                  <td>
                    <div ref={ref} className="flex items-center gap-0.5">
                      <Avatar name={name} src={row.photo} className="size-6" />
                      <div>
                        <Typography variant="body" className="mb-0.25">
                          {name}
                        </Typography>
                        <Typography variant="smallBody" className="text-muted">
                          {row.email || `-`}
                        </Typography>
                      </div>
                    </div>
                  </td>
                )
              },
            },
            {
              title: "Pending Reviews",
              id: "pending_reviews",
              columnComponent: ({ row }) => (
                <td>
                  <Typography>{row.pendingReviews || `-`}</Typography>
                </td>
              ),
            },
            {
              title: "Submitted",
              id: "submitted",
              columnComponent: ({ row }) => (
                <td>
                  <Typography>
                    {`${row.totalSubmissions} of ${row.totalAssignments}`}
                  </Typography>
                </td>
              ),
            },
            {
              title: totalRecords
                ? `${students.length} of ${totalRecords} records`
                : "",
              id: "",
              align: "right",
              columnComponent: ({ row }) => (
                <td align="right">
                  <Link
                    to={routeBuilder(routes.students.view, {
                      id: row.userUuid,
                    })}
                  >
                    <Button variant="text" className="min-w-18">
                      View Student
                    </Button>
                  </Link>
                </td>
              ),
            },
          ]}
          multiSort={false}
        />
      )}
    />
  )
}

export default StudentsList
