export const routes = {
  root: "/",
  home: "/home/",

  assignment: {
    list: "/assignments/",
    view: "/assignments/:uuid/",
    student: "/assignments/student/:id/",
  },

  submission: {
    view: "/submissions/:id/",
  },

  students: {
    list: "/students/",
    view: "/students/:id/",
  },

  classes: {
    home: "/classes/",
    class: "/classes/class/:id/",
  },

  pageNotFound: "/*",
} as const

export const oldRoutes = {
  dashboard: "/dashboard/",
  home: "/dashboard/assignments/home/",

  assignment: {
    list: "/dashboard/assignments/assignment",
    view: "/dashboard/assignments/view/:uuid/",
    student: "/dashboard/assignments/student/:id/",
  },

  submission: {
    view: "/dashboard/assignments/check/:id/",
  },

  students: {
    list: "/dashboard/assignments/student/",
  },

  classes: {
    home: "/dashboard/classes/",
    class: "/dashboard/classes/class/:id/",
  },
} as const
