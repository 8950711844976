import {
  Avatar,
  CircularProgress,
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { useEffect, useRef } from "react"
import { useScrollBack } from "utils/hooks/useScrollBack"

type Props = {
  open: boolean
  onClose: () => void
  submissionId: string
  sourceId: string
}

const PlagiarismSourceSheet = ({
  open,
  onClose,
  submissionId,
  sourceId,
}: Props) => {
  const ref = useRef<HTMLParagraphElement>(null)
  const { scrollBack } = useScrollBack(open)

  const { data, isLoading } = useQuery({
    queryKey: ["plagiarism-source", submissionId, sourceId],
    queryFn: () =>
      api.submissions.getPlagiarismSources({
        urlParams: {
          submissionId,
          sourceId,
        },
      }),
    enabled: open,
  })

  useEffect(() => {
    if (!data?.plagiarisedFromUpdatedHtml) {
      return
    }
    const showSource = () => {
      if (!ref.current) {
        return
      }
      const element = ref.current.querySelector(
        ".suraasa-plagiarism-original-source"
      )

      element && element.scrollIntoView({ behavior: "smooth", block: "center" })
    }

    setTimeout(() => {
      showSource()
    }, 500)
  }, [data?.plagiarisedFromUpdatedHtml, ref])

  return (
    <Sheet
      open={open}
      onOpenChange={open => {
        if (!open) {
          scrollBack()
          onClose()
        }
      }}
    >
      <SheetContent
        height={95}
        side="bottom"
        // className="!max-w-none"
      >
        <SheetHeader>
          <div className="flex w-full items-center justify-between pr-2">
            <Typography variant="title3">Original Source</Typography>
            {data?.plagiarisedConfidence && (
              <div className="flex items-center gap-0.5">
                <Typography
                  variant="button"
                  className="rounded bg-primary-50 p-0.25 px-0.75 text-primary-600"
                >
                  Plagiarism Confidence:&nbsp;
                  {Number(data.plagiarisedConfidence).toFixed(2)}%
                </Typography>
              </div>
            )}
          </div>
        </SheetHeader>
        <SheetBody className="flex w-full flex-col gap-3">
          {isLoading && (
            <div className="flex w-full justify-center">
              <CircularProgress />
            </div>
          )}
          {data?.user && (
            <div className="flex w-full items-center gap-1">
              <Avatar
                src={data.user.profilePicture}
                name={data.user.fullName}
                className="size-6 rounded-full"
              />
              <div className="flex flex-col">
                <Typography variant="title3">{data.user.fullName}</Typography>
                <Typography variant="smallBody" className="text-secondary-500">
                  {data.user.email}
                </Typography>
              </div>
            </div>
          )}
          {data?.plagiarisedFromUpdatedHtml && (
            <div className="show-plagiarism-highlights flex flex-col rounded-lg border p-2">
              <Typography variant="title3" className="mb-2">
                Submission
              </Typography>
              <Typography
                ref={ref}
                dangerouslySetInnerHTML={{
                  __html: data.plagiarisedFromUpdatedHtml,
                }}
              />
            </div>
          )}
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default PlagiarismSourceSheet
