import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"

export const CoursesDialog = ({
  courses,
  onClose,
}: {
  courses: { name: string }[] | null | undefined
  onClose: () => void
}) => {
  courses = courses || []

  return (
    <Dialog open={courses.length > 0} onOpenChange={() => onClose()}>
      <DialogContent closeWhenInteractOutside>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="title4">Courses</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-1 p-2">
          {courses.map(item => (
            <Typography variant="strongSmallBody" key={item.name}>
              {item.name}
            </Typography>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}
