import React from "react"

type Props = {
  courses: string[]
}

const CoursesList = (props: Props) => {
  if (props.courses.length === 1) {
    return <p className="text-smallBody">{props.courses[0]}</p>
  }

  return (
    <ul className="list-disc space-y-1 ps-1">
      {props.courses
        .sort((a, b) => a.localeCompare(b))
        .map(name => (
          <li className="text-smallBody" key={name}>
            {name}
          </li>
        ))}
    </ul>
  )
}

export default CoursesList
