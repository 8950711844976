import { useSearchParams } from "react-router-dom"

export const useSearchParamsHook = <
  T extends Record<string, string | undefined>
>(
  defaultValues: T
) => {
  const [params, setSearchParams] = useSearchParams()

  const updateParam = (key: keyof T, value: string | number | undefined) => {
    setSearchParams(
      p => {
        if (value) {
          p.set(key as string, value.toString())
        } else {
          p.delete(key as string)
        }
        return p
      },
      { replace: true }
    )
  }
  const searchParams: { [K in keyof T]: string } = Object.fromEntries(
    Object.keys(defaultValues).map(k => [k, params.get(k) || ""])
  ) as { [K in keyof T]: string }

  const clearParam = (key: keyof T) => {
    setSearchParams(
      p => {
        p.delete(key as string)

        return p
      },
      { replace: true }
    )
  }

  return { searchParams, updateParam, clearParam }
}
