type ClassInfo = {
  id: number
  uuid: string
  name: string
}

export type CalendarSchedule = {
  id: number
  startTime: string
  endTime: string
  classId: ClassInfo
  course: string[]
  professor: string
  joinUrl: string | null
}

type ClassId = {
  id: number
  name: string
}

export type ClassSchedule = {
  id: number
  classId: ClassId
  startTime: string
  endTime: string
  courses: string[]
  videoMeeting: {
    meetingUrl?: string | null
  } | null
}

type Attendee = {
  uuid: string
  firstName: string
  lastName: string
  email: string
  photo: string | null
}
export enum AttendanceMark {
  PRESENT = "present",
  ABSENT = "absent",
  YET_TO_START = "yet_to_start",
}

export type MarkAttendanceResponse = {
  classSchedule: number
  status: AttendanceMark
  user: string
  uuid: string
}

type AttendanceStatus = {
  id: number
  uuid: string
  status: AttendanceMark
  user: Attendee
}

export type ClassOverview = {
  classSchedule: ClassSchedule
  attendanceStatus: AttendanceStatus[]
}
