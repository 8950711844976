import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { AISuggestedRubric, Rubric } from "./types"
import { urls } from "./urls"

export default {
  create: async ({ data }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.review.create(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  update: async ({
    urlParams,
    data,
  }: ConfigWithURLParams<"reviewId">): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(urls.review.detail(urlParams.reviewId), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  createReviewRubrics: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.review.createReviewRubrics(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  generateAIFeedback: async ({
    urlParams,
    data,
  }: ConfigWithURLParams<"submissionId">): Promise<
    APIResponse<{ feedback: string }>
  > => {
    try {
      const res = await axios.post(
        urls.review.generateAIFeedback(urlParams.submissionId),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  rubrics: {
    list: async ({
      params,
      urlParams,
    }: ConfigWithURLParams<"assignmentId">): Promise<APIResponse<Rubric[]>> => {
      try {
        const res = await axios.get(urls.rubrics.list(urlParams.assignmentId), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
    getAISuggestions: async ({
      urlParams,
    }: ConfigWithURLParams<"submissionId">): Promise<
      APIResponse<AISuggestedRubric[]>
    > => {
      try {
        const res = await axios.get(
          urls.rubrics.getAISuggestions(urlParams.submissionId)
        )

        return formatSuccessResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
}
