import { cn, Tooltip, Typography } from "@suraasa/placebo-ui"
import { ReviewResult, ReviewStatus } from "api/resources/review/types"
import { Refresh } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { snakeToTitleCase } from "utils/helpers"

export const StatusOptions = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Accepted",
    value: ReviewStatus.accepted,
  },
  {
    label: "Rejected",
    value: ReviewStatus.rejected,
  },
]

const Tag = ({
  children,
  className,
}: {
  children: string
  className?: string
}) => (
  <Typography
    variant="body"
    className={cn(className, "p-0.5 px-1.5 rounded text-center")}
  >
    {children}
  </Typography>
)

const StatusPill = ({
  status,
  result,
  order,
}: {
  status: ReviewStatus | undefined | null | string
  result: ReviewResult | undefined | null
  order: number | undefined
}) => {
  if (result === "passed") {
    return <Tag className="bg-success-50 text-success-900">Passed</Tag>
  }
  if (result === "failed") {
    return <Tag className="bg-critical-50 text-critical-900">Failed</Tag>
  }

  if (!status) {
    return <Tag className="bg-critical-50 text-critical-900">Not Reviewed</Tag>
  }

  if (
    status === ReviewStatus.notReviewed ||
    status === ReviewStatus.underReview
  ) {
    return (
      <div className="flex items-center gap-0.5">
        <Tag className="bg-warning-100 text-warning-900">Pending</Tag>
        {(order || 0) > 1 && (
          <Tooltip content="This is a re-attempt">
            <div className="flex size-3 items-center justify-center rounded-md bg-primary-500  text-surface">
              <Refresh className="size-1.75" />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  if (status === ReviewStatus.accepted) {
    return <Tag className="bg-success-50 text-success-900">Accepted</Tag>
  }

  if (status === ReviewStatus.rejected) {
    return <Tag className="bg-critical-50 text-critical-900">Rejected</Tag>
  }

  if (status === ReviewStatus.plagiarised) {
    return <Tag className="bg-critical-50 text-critical-900">Plagiarised</Tag>
  }

  // Ideally this should not be triggered
  return (
    <Tag className="bg-critical-50 text-critical-900">
      {capitalize(snakeToTitleCase(status))}
    </Tag>
  )

  // throw new Error(`Invalid status: ${status}`)
}

export default StatusPill
