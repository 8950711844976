import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import AsyncBuilder from "components/AsyncBuilder"
import { Xmark } from "iconoir-react"

type Props = {
  onClose: () => void
  open: boolean
  onAccept: () => Promise<void>
}

const SubmitReview = ({ open, onClose, onAccept }: Props) => {
  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent>
        <DialogBody>
          <div className="mb-2 flex items-center justify-between">
            <Typography variant="title2">Submit Review?</Typography>

            <IconButton onClick={onClose} color="black">
              <Xmark />
            </IconButton>
          </div>
          <Typography>
            This will notify the student that their submission has been
            reviewed. <br />
            After confirming, you cannot edit the review.
          </Typography>
          <div className="mt-2 text-end">
            <AsyncBuilder
              handler={onAccept}
              render={({ loading, onClick }) => (
                <Button onClick={onClick} loading={loading}>
                  Submit Review
                </Button>
              )}
            />
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default SubmitReview
