import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import AsyncBuilder from "components/AsyncBuilder"
import { Xmark } from "iconoir-react"
import { useState } from "react"

type Props = {
  onClose: () => void
  open: boolean
  onReject: (isPlagiarised: boolean) => Promise<void>
}

const RejectSubmission = ({ open, onClose, onReject }: Props) => {
  const [isPlagiarised, setIsPlagiarised] = useState(false)

  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent>
        <DialogBody>
          <div className="mb-2 flex items-center justify-between">
            <Typography variant="title2">Reject Submission?</Typography>

            <IconButton onClick={onClose} color="black">
              <Xmark />
            </IconButton>
          </div>
          <div className="flex flex-col gap-2">
            <Typography>
              <Typography>
                You are rejecting this submission. This means that the marked
                rubrics will not be considered and the user will be given a
                score of <strong>ZERO</strong>. <br /> If you want the learner
                to see the rubrics, then please use the{" "}
                <strong>&quot;Submit Review&quot;</strong> button.
              </Typography>
              <br />
              This will notify the student that their submission has been
              rejected. <br />
              After confirming, you cannot edit the review.
            </Typography>
            <Checkbox
              color="critical"
              checked={isPlagiarised}
              onCheckedChange={checked => {
                if (typeof checked === "boolean") {
                  setIsPlagiarised(checked)
                }
              }}
              label="Mark this submission as plagiarised"
              containerClass="flex gap-1 items-center"
            />
          </div>
          <div className="mt-2 text-end">
            <AsyncBuilder
              handler={() => onReject(isPlagiarised)}
              render={({ loading, onClick }) => (
                <Button onClick={onClick} color="critical" loading={loading}>
                  Reject Submission
                </Button>
              )}
            />
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default RejectSubmission
