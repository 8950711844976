import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"

interface Props {
  message?: string
  className?: string
}

const NoDataCard = ({ className, message = "No records found." }: Props) => {
  return (
    <div
      className={clsx(
        "rounded border border-surface-200 bg-white",
        "flex items-center justify-center p-3",
        className
      )}
    >
      <Typography>{message}</Typography>
    </div>
  )
}

export default NoDataCard
