export enum ReviewStatus {
  accepted = "accepted",
  rejected = "rejected",
  plagiarised = "plagiarised",
  notReviewed = "not_reviewed",
  underReview = "under_review",
}

export enum ReviewResult {
  passed = "passed",
  failed = "failed",
}

export type Review = {
  professor: string
  result: ReviewResult
  uuid: string
  status: ReviewStatus
  comments: string
  marks: number
  datePublished: string
}

export type ReviewRubric = Rubric & {
  marked: RubricCell
}

export type Rubric = {
  id: string
  criteria: string
  task: string
  allComments: RubricCell[]
}

type RubricCell = {
  comments: string
  scale: number
  uuid: string
}

export type AISuggestedRubric = {
  id: number
  rubricScore: number
  reason: string
  submission: string
  rubricCell: string
}
