import assignments from "./resources/assignments"
import auth from "./resources/auth"
import courses from "./resources/courses"
import lectures from "./resources/lectures"
import review from "./resources/review"
import submissions from "./resources/submissions"

export default {
  auth,
  review,
  lectures,
  submissions,
  courses,
  assignments,
}
