import { Button, ButtonProps, cn } from "@suraasa/placebo-ui"
import { ArrowLeft } from "iconoir-react"
import { useCallback } from "react"
import { useNavigate } from "react-router"

export type BackButtonProps = {
  onBack?: () => void
  delta?: number
  className?: string
  disabled?: boolean
  noRouteHandler?: () => void
} & Partial<ButtonProps>

const BackButton = ({
  className,
  delta = -1,
  onBack,
  disabled = false,
  noRouteHandler,
  ...otherProps
}: BackButtonProps) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (onBack) {
      onBack()
      return
    }
    if (window.history.state !== null && window.history.state.idx >= 1) {
      navigate(delta)
      return
    }
    if (noRouteHandler) {
      noRouteHandler()
      return
    }

    navigate("/")
    return
  }, [navigate, onBack, delta])

  return (
    <div>
      <Button
        className={cn(className, "")}
        disabled={disabled}
        startAdornment={<ArrowLeft className="size-2.25" />}
        variant="text"
        onClick={handleClick}
        {...otherProps}
      >
        Back
      </Button>
    </div>
  )
}

export default BackButton
