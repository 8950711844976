import "froala-editor/css/froala_editor.pkgd.min.css"
import "froala-editor/css/froala_style.min.css"
import "froala-editor/js/froala_editor.pkgd.min.js"
import "froala-editor/js/plugins.pkgd.min.js"

import React from "react"
import FroalaEditorComponent from "react-froala-wysiwyg"
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView"

import { getFroalaConfig } from "./config"

const Editor = ({ value, viewOnly }: { value: string; viewOnly?: boolean }) => {
  if (viewOnly) {
    return (
      <div className="[&_li]:m-auto [&_ul]:m-[1em] [&_ul]:list-disc [&_ul]:p-[1em]">
        <FroalaEditorView
          model={value}
          config={{
            ...getFroalaConfig({ disabled: true }),
          }}
        />
      </div>
    )
  }
  return (
    <FroalaEditorComponent
      tag="textarea"
      model={value}
      config={{
        iframe: true,
      }}
    />
  )
}

export default Editor
