import "./styles/global.css"

import * as Sentry from "@sentry/react"
import { getAuthInfo } from "utils/auth"

import App from "./App"

if (!import.meta.env.VITE_SSO_URL) {
  throw new Error("Please add VITE_SSO_URL in your environment variables.")
}

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
  })
  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

import ErrorScreen from "components/ErrorScreen"
import { createRoot } from "react-dom/client"
const container = createRoot(document.getElementById("root") as HTMLElement)

container.render(
  <Sentry.ErrorBoundary
    fallback={
      <ErrorScreen error="An unexpected error occurred. Please try reloading the page." />
    }
  >
    <App />
  </Sentry.ErrorBoundary>
)
