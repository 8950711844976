import { captureException } from "@sentry/react"
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { getAuthInfo } from "utils/auth"
import { AUTHORIZATION_HEADER_PREFIX } from "utils/constants"

import { refreshToken } from "./refreshToken"
import { urls } from "./resources/auth/urls"
import { paramsSerializer, transformRequest, transformResponse } from "./utils"

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  withCredentials: true,
  paramsSerializer,
  transformRequest: [
    transformRequest,
    ...(Array.isArray(Axios.defaults.transformRequest)
      ? Axios.defaults.transformRequest
      : []),
  ],
  transformResponse: [
    ...(Array.isArray(Axios.defaults.transformResponse)
      ? Axios.defaults.transformResponse
      : []),
    transformResponse,
  ],
  headers: {
    "Content-Type": "application/json",
    Language: "en",
  },
})

const authorizationIsEmpty = (request: AxiosRequestConfig) => {
  /**
   * If we send null in Authorization header from where the API is being called, axios transforms it into an empty object `{}`
   * That is why we are checking for object length and not null
   */
  return (
    request.headers["Authorization"] === null ||
    (typeof request.headers["Authorization"] === "object" &&
      Object.keys(request.headers["Authorization"]).length === 0)
  )
}
axios.interceptors.request.use(async request => {
  const authInfo = getAuthInfo()

  /**
   * Empty "Authorization" header means that we don't want to send this Authorization in the request even if accessToken is present in the localStorage
   * Example: in refresh token API call, we explicitly send Authorization: null from the API call.
   */
  if (authorizationIsEmpty(request)) {
    delete request.headers["Authorization"]
  } else {
    if (authInfo) {
      // TODO: Update this using date-fns
      // const isAuthTokenExpired = authInfo.expiresAt
      //   ? moment().isAfter(moment(authInfo.expiresAt))
      //   : false

      // Refreshing Access token incase of expiry

      const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${authInfo.accessToken}`
      request.headers["Authorization"] = tokenString
    }
  }

  request.headers["Language"] = "en"

  return request
})

const interceptor = async (error: AxiosError) => {
  if (error.response && error.response.status === 429) {
    // console.log("Logging 429 on sentry", error?.request?.responseURL)
    try {
      captureException("API 429!", scope => {
        scope.setExtras({
          url: error?.request?.responseURL,
        })
        return scope
      })
    } catch (e) {
      // console.error("Safe to ignore")
    }
  }

  if (error.response && error.response.status === 401) {
    const refreshed = await refreshToken()
    if (refreshed)
      // Retrying the failed api call with new access token
      return axios(error.response.config)
  }

  return Promise.reject(error)
}

const successInterceptor = async (response: AxiosResponse<any>) => {
  // Verify access token API
  if (response.config.url === urls.auth.verifyAccessToken())
    if (response.data.data.verified === false) {
      const refreshed = await refreshToken()
      if (refreshed) {
        const newToken = getAuthInfo()?.accessToken
        if (newToken) {
          // Retry the request with new token
          response.config.data = JSON.stringify({ token: newToken })
          return axios(response.config)
        }
      }
    }

  return Promise.resolve(response)
}

axios.interceptors.response.use(successInterceptor, interceptor)

export default axios
