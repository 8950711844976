import { Button, Typography } from "@suraasa/placebo-ui"
import api from "api"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import { getAuthInfo, saveAuthInfo } from "utils/auth"
import { USER_TYPE } from "utils/constants"
import { getPlatformURL } from "utils/helpers"
import { routes } from "utils/routes"

const SSO = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const next = searchParams.get("next")
  const getPlatformToken = async (code: string) => {
    setLoading(true)
    try {
      const res = await api.auth.generatePlatformToken({
        data: {
          code,
          platform: USER_TYPE,
        },
      })
      saveAuthInfo(res)

      setLoading(false)
      navigate(next ?? routes.home, { replace: true })
    } catch (error) {
      setLoading(false)
      setError("Something went wrong, please try again!")
    }
  }

  const getCode = () => {
    window.location.href = getPlatformURL(
      "sso",
      `/?platform=${USER_TYPE}&redirect-url=${window.location.href}`
    )
  }

  useEffect(() => {
    if (getAuthInfo()) {
      setLoading(false)
      navigate(next ?? routes.home, { replace: true })
      return
    }

    const code = searchParams.get("code")
    if (code) {
      getPlatformToken(code)
    } else {
      getCode()
      setLoading(false)
    }
    /**
     * Should only be called on mount
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <LoadingOverlay showLogo />

  return error && !loading ? (
    <div
      className={
        "flex h-screen flex-col items-center justify-center [&_#logo]:translate-x-5"
      }
    >
      <div className="mb-1">
        <Typography variant="title3">{error}</Typography>
      </div>
      <Button onClick={getCode}>Click here to try again</Button>
    </div>
  ) : null
}

export default SSO
