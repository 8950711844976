import axios from "api/axios"
import { APIResponse, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  PlagiarismSource,
  PreviousSubmissionListItem,
  Submission,
  SubmissionPlagiarismReport,
} from "./types"
import { urls } from "./urls"

export default {
  retrieve: async ({
    params,
    urlParams,
  }: ConfigWithURLParams<"submissionId">): Promise<APIResponse<Submission>> => {
    try {
      const res = await axios.get(
        urls.submissions.retrieve(urlParams.submissionId),
        {
          params,
        }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  listAssignmentUserSubmissions: async ({
    params,
    urlParams,
  }: ConfigWithURLParams<"assignmentId" | "userId">): Promise<
    APIResponse<PreviousSubmissionListItem[]>
  > => {
    try {
      const res = await axios.get(
        urls.submissions.listAssignmentUserSubmissions(
          urlParams.assignmentId,
          urlParams.userId
        ),
        {
          params,
        }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getPlagiarismSources: async ({
    params,
    urlParams,
  }: ConfigWithURLParams<"submissionId" | "sourceId">): Promise<
    APIResponse<PlagiarismSource>
  > => {
    try {
      const res = await axios.get(
        urls.plagiarism.getPlagiarismSources(
          urlParams.submissionId,
          urlParams.sourceId
        ),
        { params }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getPlagiarismReport: async ({
    urlParams,
  }: ConfigWithURLParams<"submissionId">): Promise<
    APIResponse<SubmissionPlagiarismReport>
  > => {
    try {
      const res = await axios.get(
        urls.plagiarism.getPlagiarisedSubmission(urlParams.submissionId)
      )

      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
