import { useEffect, useState } from "react"

// Sheet removes the body.overflow, so before that happens it records the last position
// and scroll back to that position when any sheet or overlay closes
export const useScrollBack = (initOpen: boolean) => {
  const [topPos, setTopPos] = useState<number | null>(null)

  const scrollBack = () => {
    if (topPos) {
      setTimeout(() => {
        window.scrollTo({
          top: topPos,
          behavior: "instant",
        })
        setTopPos(0)
      }, 10)
    }
  }
  useEffect(() => {
    if (initOpen) {
      const scrollPos = window.scrollY
      setTopPos(scrollPos)
    }
  }, [initOpen])

  return { scrollBack }
}
