import { useState } from "react"

type RenderProps = {
  onClick: Props["handler"]
  loading: boolean
}

type Props = {
  handler: () => Promise<any>
  render: (renderProps: RenderProps) => JSX.Element
  delay?: number
}

const AsyncBuilder = (props: Props) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    const res = await props.handler()

    setTimeout(() => {
      setLoading(false)
    }, props.delay || 0)

    return res
  }

  return props.render({ onClick: handleClick, loading })
}

export default AsyncBuilder
