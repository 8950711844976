import {
  Avatar,
  Button,
  CircularProgress,
  cn,
  Select,
  Table,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import api from "api"
import CoursesList from "components/CoursesList"
import Navbar from "components/Navbar"
import PageContainer from "components/PageContainer"
import BackButton from "components/shared/BackButton"
import TableWrapper from "components/TableWrapper"
import OverviewStatCard from "features/home/ListStudentsOrAssignments/OverviewStatCard"
import { Link, useParams } from "react-router-dom"
import { routeBuilder } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import useSearchField from "utils/hooks/useSearchField"
import { useSearchParamsHook } from "utils/hooks/useSearchParams"
import { routes } from "utils/routes"

import StatusPill, {
  StatusOptions as statusOptions,
} from "../../../components/ReviewStatusTag"
import { formatDate } from "../../../utils/helpers"
import { formatAssignmentTitle } from "../utils"

const ViewAssignment = () => {
  const { uuid: assignmentId } = useParams() as { uuid: string }

  const { searchParams, updateParam } = useSearchParamsHook({
    status: "",
  })

  const { SearchField, searchText: search } = useSearchField({
    placeholder: "Search by name or email...",
    useSearchParam: true,
  })

  const overview = useQuery({
    queryKey: ["assignment-overview", assignmentId],
    queryFn: () =>
      api.assignments.overview({
        urlParams: {
          assignmentId,
        },
      }),
  })

  const { isFetching, hasNextPage, fetchNextPage, data, isLoading } =
    useInfiniteQuery({
      queryKey: [
        "assignment-user-list-by-submissionId-id",
        assignmentId,
        search,
        searchParams.status,
      ],
      queryFn: x =>
        api.assignments.users.listAllUserSubmissionsByAssignmentId({
          urlParams: {
            assignmentId: assignmentId,
          },
          params: {
            page: x.pageParam,
            search,
            status: searchParams.status || null,
            fields: ["status"],
          },
        }),
      initialPageParam: 1,
      getNextPageParam: lastPage => {
        return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
      },
    })

  const submissions =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  const totalRecords = data?.pages[0].data.total

  if (overview.isLoading || !overview.data) {
    return (
      <>
        <Navbar />
        <PageContainer className="my-4">
          <div className="my-2 flex justify-center">
            <CircularProgress />
          </div>
        </PageContainer>
      </>
    )
  }

  const {
    assignment,
    allocatedUsers,
    acceptedSubmissions,
    totalSubmissions,
    pendingReviews,
  } = overview.data

  return (
    <>
      <Navbar gutterBottom={false} hideBackButton />

      <PageContainer className="border-b">
        <div className="flex justify-between py-2">
          <div>
            <Typography variant="title2" className="mb-1">
              {formatAssignmentTitle(assignment)}
            </Typography>
            <CoursesList courses={assignment.courses} />
          </div>
          {/* <div>
            <Button size="sm">Start Checking</Button>
          </div> */}
        </div>
      </PageContainer>

      <PageContainer>
        <div className="mt-2 flex flex-col gap-3">
          <BackButton />

          <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-4">
            <OverviewStatCard
              color="bg-primary-300"
              count={allocatedUsers}
              title="Total Allocated Users"
            />
            <OverviewStatCard
              color="bg-warning-300"
              count={totalSubmissions}
              title="Total Submissions"
            />
            <OverviewStatCard
              color="bg-success-300"
              count={acceptedSubmissions}
              title="Accepted Submissions"
            />
            <OverviewStatCard
              color="bg-critical-300"
              count={pendingReviews}
              title="Pending Reviews"
            />
          </div>

          <div className="flex gap-1">
            {SearchField}
            <Select
              options={statusOptions}
              placeholder="Status"
              isClearable
              onChange={option => {
                updateParam("status", option?.value)
              }}
              value={
                statusOptions.find(v => v.value === searchParams.status) || null
              }
              className="basis-2/4"
            />
          </div>
        </div>

        <TableWrapper
          initialLoading={isLoading && isFetching}
          addBottomPadding
          showNoData={!isLoading && submissions.length === 0}
          scrollLoading={!isLoading && isFetching && submissions.length > 0}
          content={className => (
            <Table
              className={cn(className, "mt-2")}
              data={submissions}
              headers={[
                {
                  title: "Name",
                  id: "name",
                  columnComponent: ({ row }) => (
                    <td>
                      <div ref={ref} className="flex items-center gap-0.5">
                        <Avatar
                          name={row.user.fullName}
                          src={row.user.photo}
                          className="size-6 shrink-0"
                        />
                        <div>
                          <Typography variant="body" className="mb-0.25">
                            {row.user.fullName}
                          </Typography>
                          <Typography
                            variant="smallBody"
                            className="text-muted"
                          >
                            {row.user.email}
                          </Typography>
                        </div>
                      </div>
                    </td>
                  ),
                },
                {
                  title: "Submissions",
                  id: "submissions",
                  columnComponent: ({ row }) => (
                    <td>
                      <Typography>{row.order || `-`}</Typography>
                    </td>
                  ),
                },
                {
                  title: "Date",
                  id: "date",
                  columnComponent: ({ row }) => (
                    <td>
                      <div className="min-w-12">
                        <Typography>
                          {row.submittedAt
                            ? formatDate(row.submittedAt, "MMM d, yyyy")
                            : `-`}
                        </Typography>
                      </div>
                    </td>
                  ),
                },
                {
                  title: "Reviewer",
                  id: "reviewer",
                  columnComponent: ({ row }) => (
                    <td>
                      <div className="min-w-18">
                        <Typography>{row.review?.professor || "-"}</Typography>
                      </div>
                    </td>
                  ),
                },
                {
                  title: "Status",
                  id: "status",
                  columnComponent: ({ row }) => (
                    <td>
                      <div className="flex items-center gap-1">
                        <StatusPill
                          status={row.status}
                          result={row.review?.result}
                          order={row.order}
                        />
                      </div>
                    </td>
                  ),
                },

                {
                  title: totalRecords
                    ? `${submissions.length} of ${totalRecords} records`
                    : "",
                  id: "",
                  columnComponent: ({ row }) => (
                    <td>
                      <Link
                        to={routeBuilder(routes.submission.view, {
                          id: row.uuid,
                        })}
                      >
                        <Button variant="text">View Submission</Button>
                      </Link>
                    </td>
                  ),
                },
              ]}
              multiSort={false}
            />
          )}
        />
      </PageContainer>
    </>
  )
}

export default ViewAssignment
