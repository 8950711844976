import "react-big-calendar/lib/css/react-big-calendar.css"

import { Button, Select, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { CalendarSchedule } from "api/resources/lectures/types"
import LoadingOverlay from "components/shared/LoadingOverlay"
import {
  addMonths,
  endOfMonth,
  isSameMonth,
  isSameYear,
  startOfMonth,
  subMonths,
} from "date-fns"
import format from "date-fns/format"
import getDay from "date-fns/getDay"
import enUS from "date-fns/locale/en-US"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import { ArrowLeft, ArrowRight } from "iconoir-react"
import { useMemo, useState } from "react"
import {
  Calendar,
  CalendarProps,
  Components,
  dateFnsLocalizer,
  EventProps,
} from "react-big-calendar"
import { Link } from "react-router-dom"
import { formatDate, routeBuilder } from "utils/helpers"
import { useSearchParamsHook } from "utils/hooks/useSearchParams"
import { routes } from "utils/routes"

import { ClassesDialog, ClassEvent } from "./ClassesDialog"

const locales = {
  "en-US": enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const ClassesCalendar = () => {
  const [date, setDate] = useState<Date>(new Date())
  const [showMoreEvents, setShowMoreEvents] = useState<ClassEvent[]>([])

  const { searchParams, updateParam } = useSearchParamsHook({
    calenderCourse: "",
  })

  const { data: coursesData, isLoading: courseLoading } = useQuery({
    queryFn: () => api.courses.list(),
    queryKey: ["courses"],
  })
  const filterCourse = coursesData?.courses.find(
    c => c.uuid === searchParams.calenderCourse
  )

  const { isLoading, data } = useQuery({
    queryKey: ["calendarTableSchedule", date, filterCourse],
    queryFn: () =>
      api.lectures.getCalendarSchedule({
        params: {
          page: "all",
          course: filterCourse?.uuid || null,
          start_date: date
            ? formatDate(startOfMonth(date).toDateString(), "yyyy-MM-dd")
            : null,
          end_date: date
            ? formatDate(endOfMonth(date).toDateString(), "yyyy-MM-dd")
            : null,
        },
      }),
  })

  const onNavigate = (newDate: Date) => {
    if (!isSameYear(date, newDate)) {
      setDate(newDate)
    } else {
      !isSameMonth(date, newDate) && setDate(newDate)
    }
  }
  const eventsData = data?.data.data

  const formattedEvents: EventProps["event"][] | undefined = eventsData
    ? eventsData.map(e => {
        return {
          start: new Date(e.startTime),
          end: new Date(e.endTime),
          title: e.classId.name,
          resource: e,
        }
      })
    : undefined

  const EventWrapper: Components["eventWrapper"] = e => {
    const resource = e.event.resource as CalendarSchedule

    return (
      <div className="flex h-3 w-full cursor-pointer  items-center rounded bg-success-500 p-0.25">
        <Link
          to={routeBuilder(routes.classes.class, {
            id: String(resource.id),
          })}
        >
          <Typography variant="smallBody" className="truncate text-white">
            {resource.classId.name}
          </Typography>
        </Link>
      </div>
    )
  }
  const components = useMemo((): CalendarProps["components"] => {
    return {
      eventWrapper: EventWrapper,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="relative">
      <ClassesDialog
        classes={showMoreEvents}
        onClose={() => setShowMoreEvents([])}
      />
      {isLoading && <LoadingOverlay />}

      <div className="mb-2 flex flex-wrap items-center gap-2 md:flex-nowrap">
        <div className="grow basis-3/5">
          <Select
            options={coursesData?.courses}
            isLoading={courseLoading}
            placeholder="Course"
            onChange={v => updateParam("calenderCourse", v?.uuid)}
            value={filterCourse || null}
            isClearable
            getOptionLabel={(option: { name: any }) => `${option.name}`}
            getOptionValue={option => (option ? option.uuid : "")}
          />
        </div>
        <div className="flex grow items-center justify-between">
          <div>
            <Button
              variant="text"
              className="text-onSurface-600"
              onClick={() => onNavigate(subMonths(date, 1))}
            >
              <ArrowLeft className="size-3" />
            </Button>
          </div>
          <div>
            <Typography variant="strong">
              {formatDate(date.toDateString(), "MMMM yyyy")}
            </Typography>
          </div>
          <div>
            <Button
              variant="text"
              className="text-onSurface-600"
              onClick={() => onNavigate(addMonths(date, 1))}
            >
              <ArrowRight className="size-3" />
            </Button>
          </div>
        </div>
      </div>
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        components={components}
        events={formattedEvents}
        onNavigate={onNavigate}
        doShowMoreDrillDown={false}
        onShowMore={events => {
          setShowMoreEvents(events)
        }}
        drilldownView={null}
        date={date}
        toolbar={false}
      />
    </div>
  )
}

export default ClassesCalendar
