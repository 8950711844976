// Reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
import api from "api"
import {
  clearAuthInfo,
  getAuthInfo,
  redirectToLogin,
  saveAuthInfo,
} from "utils/auth"

let isTokenRefreshing = false

const promises: (() => void)[] = []

const forceLogin = () => {
  clearAuthInfo()
  redirectToLogin()
}

export async function refreshToken(): Promise<boolean> {
  const authInfo = getAuthInfo()
  if (!authInfo) {
    forceLogin()
    return false
  }

  if (isTokenRefreshing) {
    // getting reference to promise's resolve method so we can call it from outside
    let resolvePromise: any
    const currentRequestPromise = new Promise<boolean>(resolve => {
      resolvePromise = resolve
    })
    // pushing all the promises in the array so they can be resolved later
    promises.push(() => resolvePromise())

    // waiting for the new refresh token from first api call
    return await currentRequestPromise
  }

  isTokenRefreshing = true

  let refreshed = false
  try {
    const refreshToken = authInfo.refreshToken

    if (!refreshToken) throw new Error("User is already logged out")
    const res = await api.auth.refreshToken({
      data: {
        refreshToken,
      },
      headers: {
        Authorization: null,
      },
    })

    const newAuthInfo = {
      ...authInfo,
      accessToken: res.accessToken,
      refreshToken: res.refreshToken,
    }
    saveAuthInfo(newAuthInfo)
    refreshed = true
  } catch (e) {
    console.info("> Refresh token expired. Logging out.")
  }

  // resolving all the pending promises so awaited code can resume execution
  isTokenRefreshing = false
  promises.splice(0).forEach(callBack => {
    callBack()
  })

  if (!refreshed) {
    forceLogin()
  }

  return refreshed
}
