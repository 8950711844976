import {
  Button,
  CircularProgress,
  cn,
  Select,
  Table,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import api from "api"
import TableWrapper from "components/TableWrapper"
import { useNavigate } from "react-router-dom"
import { formatDate, routeBuilder } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import useSearchField from "utils/hooks/useSearchField"
import { useSearchParamsHook } from "utils/hooks/useSearchParams"
import { routes } from "utils/routes"

import { DatePicker } from "@/components/DatePicker"

const ClassesTable = () => {
  const navigate = useNavigate()

  const { searchParams, updateParam } = useSearchParamsHook({
    tableCourse: "",
    from: "",
    to: "",
  })

  const { from: fromDate, to: toDate } = searchParams

  const { data: coursesData, isLoading: courseLoading } = useQuery({
    queryFn: () => api.courses.list(),
    queryKey: ["courses"],
  })

  const { SearchField, searchText: search } = useSearchField({
    className: "w-full",
    placeholder: "Search Lecture",
    useSearchStyles: false,
    useSearchParam: true,
  })

  const filterCourse = coursesData?.courses.find(
    c => c.uuid === searchParams.tableCourse
  )

  const { isFetching, hasNextPage, fetchNextPage, isLoading, data } =
    useInfiniteQuery({
      queryKey: [
        "calendarTableSchedule",
        search,
        filterCourse,
        fromDate,
        toDate,
      ],
      queryFn: x =>
        api.lectures.getCalendarSchedule({
          params: {
            page: x.pageParam,
            search,
            course: filterCourse?.uuid || null,
            start_date: fromDate ? formatDate(fromDate, "yyyy-MM-dd") : null,
            end_date: toDate ? formatDate(toDate, "yyyy-MM-dd") : null,
          },
        }),
      initialPageParam: 1,
      getNextPageParam: lastPage => {
        return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
      },
    })

  const schedule =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })
  const totalRecords = data?.pages[0].data.total

  return (
    <div>
      <div className="flex flex-col gap-1">
        <div className="flex gap-1">
          <div className="w-full">{SearchField}</div>
          <Select
            options={coursesData?.courses}
            isLoading={courseLoading}
            placeholder="Course"
            onChange={v => updateParam("tableCourse", v?.uuid)}
            value={filterCourse || null}
            isClearable
            getOptionLabel={(option: { name: any }) => `${option.name}`}
            getOptionValue={option => (option ? option.uuid : "")}
          />
        </div>
        <div className="flex gap-1">
          <DatePicker
            value={fromDate ? new Date(fromDate) : undefined}
            placeholder="From Date"
            onDateChange={d => updateParam("from", d?.toISOString())}
          />
          <DatePicker
            value={toDate ? new Date(toDate) : undefined}
            placeholder="To Date"
            onDateChange={d => updateParam("to", d?.toISOString())}
          />
        </div>
      </div>
      <div>
        <TableWrapper
          initialLoading={isFetching && isLoading}
          addBottomPadding
          scrollLoading={!isLoading && isFetching && schedule.length > 0}
          showNoData={!isLoading && schedule.length === 0}
          content={className => (
            <Table
              className={cn(className, "mt-2")}
              data={schedule}
              headers={[
                {
                  title: "Name",
                  id: "name",
                  columnComponent: ({ row }) => (
                    <td>
                      <div ref={ref} className="min-w-20">
                        <Typography
                          color="onSurface.800"
                          variant="strongSmallBody"
                        >
                          {row.classId.name}
                        </Typography>
                      </div>
                    </td>
                  ),
                },
                {
                  title: "Course",
                  id: "course",
                  columnComponent: ({ row: { course } }) => (
                    <td>
                      <Typography variant="smallBody">
                        {course.length > 2 ? (
                          <>
                            {course
                              .slice(0, 2)

                              .join(", ")}
                            <Button
                              size="sm"
                              variant="text"
                              className="!p-0.5 !text-sm"
                              onClick={() => {
                                // setShowCourses(course)
                              }}
                            >
                              +{course.length - 2} more
                            </Button>
                          </>
                        ) : (
                          course.join(", ")
                        )}
                      </Typography>
                    </td>
                  ),
                },
                {
                  title: "Time",
                  id: "time",
                  columnComponent: ({ row }) => (
                    <td>
                      <div className="min-w-12">
                        <Typography
                          variant="smallBody"
                          className="text-onSurface-800"
                        >
                          {`${formatDate(
                            row.startTime,
                            "MMM dd, h:mm a"
                          )} - ${formatDate(row.endTime, "MMM dd, h:mm a")}`}
                        </Typography>
                      </div>
                    </td>
                  ),
                },

                {
                  title: totalRecords
                    ? `${schedule.length} of ${totalRecords} records`
                    : "",
                  id: "",
                  columnComponent: ({ row }) => (
                    <td>
                      <div className="min-w-14">
                        <Button
                          variant="text"
                          className="w-full"
                          onClick={() =>
                            navigate(
                              routeBuilder(routes.classes.class, {
                                id: String(row.id),
                              })
                            )
                          }
                        >
                          View Lecture
                        </Button>
                      </div>
                    </td>
                  ),
                },
              ]}
              multiSort={false}
            />
          )}
        />
      </div>
    </div>
  )
}

export default ClassesTable
