import { CircularProgress } from "@suraasa/placebo-ui"

type Props = {
  /**
   * if true renders `Suraasa Butterfly` instead of `CircularProgress`
   */
  showLogo?: boolean
  children?: React.ReactNode
}

const LoadingOverlay: React.FC<Props> = ({ children, showLogo = false }) => {
  return (
    <div
      className={
        "absolute left-0 top-0 z-[theme(zIndex.dialog)] flex size-full flex-col items-center justify-center rounded-lg bg-[rgba(0,_0,_0,_0.1)]"
      }
    >
      {showLogo ? (
        <img
          src="https://assets.suraasa.com/logos/suraasa-logo-mini.svg"
          fetchPriority="high"
          alt=""
        />
      ) : (
        <CircularProgress size="md" />
      )}
      {children}
    </div>
  )
}

export default LoadingOverlay
