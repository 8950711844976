export const AUTHORIZATION_HEADER_PREFIX = "Bearer"

export const BROWSER_STORAGE_KEYS = {
  auth: "auth",
}

export enum ServicePrefix {
  nebula = "nebula",
  solis = "solis",
  celestial = "celestial",
  hades = "hades",
  gravity = "gravity",
  doppler = "doppler",
  mercury = "mercury",
}

export const USER_TYPE = "Professor"
