import { CircularProgress } from "@suraasa/placebo-ui"
import api from "api"
import { GlobalContext } from "components/GlobalState"
import { useContext, useEffect, useState } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { routes } from "utils/routes"

const PrivateRoute = () => {
  const [authIsValid, setAuthIsValid] = useState<boolean>(false)

  const { setProfile } = useContext(GlobalContext)
  const auth = getAuthInfo()

  useEffect(() => {
    const checkAuth = async () => {
      const authInfo = getAuthInfo()
      try {
        if (authInfo) {
          const res = await api.auth.verifyAccessToken({
            data: {
              token: authInfo.accessToken,
            },
            headers: {
              Authorization: null,
            },
          })

          setAuthIsValid(res.verified)
        }
      } catch (e) {
        console.log("> Access token is invalid", e)
      }
    }

    if (getAuthInfo()) {
      checkAuth()
    } else {
      setAuthIsValid(true)
    }
  }, [])

  useEffect(() => {
    if (auth) {
      setProfile(auth.user)
    }
  }, [])

  if (auth && !authIsValid) {
    return (
      <div className="flex h-screen w-full items-center justify-center p-5">
        <CircularProgress size="md" />
      </div>
    )
  }

  if (auth && authIsValid) {
    return <Outlet />
  }

  return (
    <Navigate
      to={{
        pathname: routes.root,
        search: `?next=${window.location.pathname}`,
      }}
      replace
    />
  )
}

export default PrivateRoute
