import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  PaginatedResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import {
  AssignmentListItem,
  AssignmentOverview,
  AssignmentUserListItem,
  AssignmentUserOverview,
  AssignmentUserSubmissionByAssignmentIdListItem,
  AssignmentUserSubmissionByUserIdListItem,
} from "./types"
import { urls } from "./urls"

export default {
  list: async ({
    params,
  }: Config): Promise<PaginatedResponse<AssignmentListItem[]>> => {
    try {
      const res = await axios.get(urls.assignments.list(), {
        params,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  overview: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<AssignmentOverview>> => {
    try {
      const res = await axios.get(
        urls.assignments.overview(urlParams.assignmentId),
        {
          params,
        }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  users: {
    list: async ({
      params,
    }: Config): Promise<PaginatedResponse<AssignmentUserListItem[]>> => {
      try {
        const res = await axios.get(urls.users.list(), {
          params,
        })
        return formatPaginatedResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },

    listAllUserSubmissionsByUserId: async ({
      urlParams,
      params,
    }: ConfigWithURLParams<"studentId">): Promise<
      PaginatedResponse<AssignmentUserSubmissionByUserIdListItem[]>
    > => {
      try {
        const res = await axios.get(
          urls.users.listSubmissionsByUserId(urlParams.studentId),
          {
            params,
          }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
    listAllUserSubmissionsByAssignmentId: async ({
      urlParams,
      params,
    }: ConfigWithURLParams<"assignmentId">): Promise<
      PaginatedResponse<AssignmentUserSubmissionByAssignmentIdListItem[]>
    > => {
      try {
        const res = await axios.get(
          urls.users.listSubmissionsByAssignmentId(urlParams.assignmentId),
          {
            params,
          }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
    overview: async ({
      params,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<AssignmentUserOverview>> => {
      try {
        const res = await axios.get(urls.users.overview(urlParams.studentId), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
}
