import { CircularProgress, cn } from "@suraasa/placebo-ui"
import NoDataCard from "features/Assignments/NoDataCard"

const TableWrapper = ({
  content,
  initialLoading,
  addBottomPadding,
  scrollLoading,
  showNoData,
}: {
  content: (className: string) => JSX.Element
  initialLoading?: boolean
  addBottomPadding?: boolean
  scrollLoading?: boolean
  showNoData: boolean
}) => {
  const className =
    "[&_td]:h-6 [&_td_th]:p-1 [&_td]:pr-4 [&_th]:pr-4 [&_thead]:h-6 [&_thead]:border-b [&_thead]:border-b-surface-200 [&_thead]:text-[16px] [&_thead]:text-onSurface-700 [&_tr]:h-6 [&_tr]:border-b [&_tr]:border-b-surface-200 rounded-b-none rounded-t-xl border border-surface-200 [&_th:first-child]:pl-4 [&_td:first-child]:pl-4"

  if (initialLoading) {
    return (
      <div
        className={cn(
          "my-2 py-4 flex justify-center border rounded-xl border-surface-200"
        )}
      >
        <CircularProgress className="size-4" />
      </div>
    )
  }

  return (
    <>
      {content(cn(className))}
      {scrollLoading && (
        <div
          className={cn(
            "flex justify-center",
            addBottomPadding ? "mt-2" : "my-2"
          )}
        >
          <CircularProgress />
        </div>
      )}
      {showNoData && <NoDataCard />}
      {addBottomPadding && <div className="h-5" />}
    </>
  )
}

export default TableWrapper
